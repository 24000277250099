import { DatePipe } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { FileUpload } from "primeng/fileupload";
import { Abitazioni } from "src/app/_interfaces/abitazioni";
import { Cliente } from "src/app/_interfaces/clienti";
import { Preventivi } from "src/app/_interfaces/preventivi";
import { StepPreventivi } from "src/app/_interfaces/stepPreventivi";
import { PreventiviService } from "src/app/_services/preventivi.service";
import { SerramentistaService } from "src/app/_services/serramentista.service";
import { StepLavoriService } from "src/app/_services/step-lavori.service";
import { environment } from "src/environments/environment";
import { Subscription } from 'rxjs';
import { Serramentista } from 'src/app/_interfaces/serramentista';
import { DateServizio } from "src/app/_interfaces/dateServizio";
import { DateServizioService } from "src/app/_services/dateservizio.service";
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: "step-preventivi",
  templateUrl: "./step-preventivi.component.html",
})
export class StepPreventiviComponent implements OnInit {
  stepPreventiviList: StepPreventivi[] = [];
  id: string;

  serramentista: Serramentista;

  ruoloUtente: string = "SE";
  baseUrl: string = environment.baseUrl;
  showButtonProcedi = false;
  showButtonApprova = false;

  creditiResidui: Number = 0;
  idUtente: string;
  stepTuttiValidati: boolean = true;

  inizioLavoriPreventivo: DateServizio[] = [];
  inizioLavori: string;

  hideLabel = false;
  subscription: Subscription;


  @Input() cliente: Cliente;
  @Input() abitazione: Abitazioni;
  @Input() preventivo: Preventivi;

  @ViewChild("objFileUpload", { static: false }) objFileUpload: FileUpload;
  upFile: File;
  display = false;
  cambiostatoStep: number = 0;
  cambiostatoDoc: number = 0;
  cambiostatoGestoreTudor: string = "";


  catastale: string[] = [
    'A/1', 'A/2', 'A/3', 'A/4', 'A/5', 'A/6', 'A/7', 'A/8', 'A/9', 'A/10', 'A/11',
    'B/1', 'B/2', 'B/3', 'B/4', 'B/5', 'B/6', 'B/7', 'B/8',
    'C/1', 'C/2', 'C/3', 'C/4', 'C/5', 'C/6', 'C/7',
    'D/1', 'D/2', 'D/3', 'D/4', 'D/5', 'D/6', 'D/7', 'D/8', 'D/9', 'D/10'
  ];

  constructor(
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private stepDocService: StepLavoriService,
    private messageSrv: MessageService,
    private preventiviSrv: PreventiviService,
    private datePipe: DatePipe,
    private msgService: MessageService,
    private serramentistaSrv: SerramentistaService,
    private dateservizioSrv: DateServizioService
  ) {

  }

  ngOnInit(): void {
    this.ruoloUtente = sessionStorage.getItem("ruolo");
    this.id = this.route.snapshot.paramMap.get("id");
    this.idUtente = sessionStorage.getItem('id');
    //ste preventivi da API
    this.refreshStepPreventivi();
    this.getCrediti();
    this.DataLavori()
   
  }
  ngOnDestroy() {
    // Annulla la sottoscrizione per evitare perdite di memoria
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getCrediti() {
    this.serramentistaSrv
      .getCrediti(String(this.preventivo.prev_user))
      .subscribe((res) => {
        this.creditiResidui = Number.parseFloat(res.data[0].saldo);
      });
  }

  DataLavori() {
    this.dateservizioSrv
      .getByTabellaCampo('preventivi', this.id)
      .subscribe((res) => {
        this.inizioLavoriPreventivo = [...res.data];
        if (this.inizioLavoriPreventivo.length > 0) {
          this.inizioLavori = this.inizioLavoriPreventivo[0].ds_data;
        }

        // Forza il rilevamento dei cambiamenti
        this.cdRef.detectChanges();
      });
  }

  refreshSerramentista(idCliente: string): void {
    this.serramentistaSrv.getSerramentista(idCliente).subscribe((res) => {
      this.serramentista = { ...res };
    });
  }

  titoloDettaglioStep: string = ""

  async refreshStepPreventivi() {
    this.stepTuttiValidati = true;
    this.id = this.route.snapshot.paramMap.get("id");

    sessionStorage.removeItem("stateStep-session");
    this.stepPreventiviList = [];

    this.preventiviSrv
      .getStepPreventivi(Number.parseInt(this.id))
      .subscribe((res) => {
        res.data.forEach((x) => {
          let step = x;
          this.stepPreventiviList.push(step);
          if (this.preventivo.prev_tipo_detrazione === 'BA') {
            if (step.pstep_numero === 1 || step.pstep_numero === 2 || step.pstep_numero === 3 || step.pstep_numero === 5) {
              if (step.pstep_nome == '') {
                this.stepTuttiValidati = false;
              }
              if (step.pstep_validato == 2 || step.pstep_validato == 3) {
                this.stepTuttiValidati = false;
              }
            }
            else {
              if (step.pstep_numero === 1 || step.pstep_numero === 2 || step.pstep_numero === 3) {
                if (step.pstep_nome == '') {
                  this.stepTuttiValidati = false;
                }
                if (step.pstep_validato == 2 || step.pstep_validato == 3) {
                  this.stepTuttiValidati = false;
                }
              }
            }

          }
        });

        if (res.data.length < 3) {
          this.stepTuttiValidati = false;
        }
        // Aggiungo gli altri documenti mancanti
        if (
          !this.stepPreventiviList.find((s) => s.pstep_numero === 100)
        ) {
          this.stepPreventiviList.push({
            pstep_id: 0,
            pstep_preventivo: Number.parseInt(this.id),
            pstep_step: 1,
            pstep_numero: 100,
            pstep_nome: "",
            pstep_tipo: "SEZIONE ANAGRAFICA",
            pstep_responsabile: "",
            pstep_allegato: "",
            pstep_validato: 0,
            pstep_data: null,
            pstep_note: "",
            pstep_data_aggiunta: null,
            pstep_data_modifica: null,
            pstep_data_val: null,
          });
        }
        if (
          !this.stepPreventiviList.find((s) => s.pstep_numero === 101)
        ) {
          this.stepPreventiviList.push({
            pstep_id: 0,
            pstep_preventivo: Number.parseInt(this.id),
            pstep_step: 1,
            pstep_numero: 101,
            pstep_nome: "",
            pstep_tipo: "SEZIONE UNITA' IMMOBILIARE",
            pstep_responsabile: "",
            pstep_allegato: "",
            pstep_validato: 0,
            pstep_data: null,
            pstep_note: "",
            pstep_data_aggiunta: null,
            pstep_data_modifica: null,
            pstep_data_val: null,
          });
        }
        if (
          !this.stepPreventiviList.find((s) => s.pstep_numero === 1)
        ) {
          this.stepPreventiviList.push({
            pstep_id: 0,
            pstep_preventivo: Number.parseInt(this.id),
            pstep_step: 1,
            pstep_numero: 1,
            pstep_nome: "",
            pstep_tipo:
              "DOCUMENTO DI RICONOSCIMENTO CLIENTE/DOCUMENTO DI RICONOSCIMENTO+CODICE FISCALE L.R.P.T.",
            pstep_responsabile: "",
            pstep_allegato: "",
            pstep_validato: 0,
            pstep_data: null,
            pstep_note: "",
            pstep_data_aggiunta: null,
            pstep_data_modifica: null,
            pstep_data_val: null,
          });
        }
        if (
          !this.stepPreventiviList.find((s) => s.pstep_numero === 2)
        ) {
          this.stepPreventiviList.push({
            pstep_id: 0,
            pstep_preventivo: Number.parseInt(this.id),
            pstep_step: 1,
            pstep_numero: 2,
            pstep_nome: "",
            pstep_tipo:
              "CODICE FISCALE CLIENTE/VISURA CAMERALE CLIENTE",
            pstep_responsabile: "",
            pstep_allegato: "",
            pstep_validato: 0,
            pstep_data: null,
            pstep_note: "",
            pstep_data_aggiunta: null,
            pstep_data_modifica: null,
            pstep_data_val: null,
          });
        }
        if (
          !this.stepPreventiviList.find((s) => s.pstep_numero === 3)
        ) {
          this.stepPreventiviList.push({
            pstep_id: 0,
            pstep_preventivo: Number.parseInt(this.id),
            pstep_step: 1,
            pstep_numero: 3,
            pstep_nome: "",
            pstep_tipo: "SCHEDA ANAGRAFICA",
            pstep_responsabile: "",
            pstep_allegato: "",
            pstep_validato: 0,
            pstep_data: null,
            pstep_note: "",
            pstep_data_aggiunta: null,
            pstep_data_modifica: null,
            pstep_data_val: null,
          });
        }
        if (
          !this.stepPreventiviList.find((s) => s.pstep_numero === 4)
        ) {
          this.stepPreventiviList.push({
            pstep_id: 0,
            pstep_preventivo: Number.parseInt(this.id),
            pstep_step: 1,
            pstep_numero: 4,
            pstep_nome: "",
            pstep_tipo: "VISURA CATASTALE IMMOBILE CLIENTE",
            pstep_responsabile: "",
            pstep_allegato: "",
            pstep_validato: 0,
            pstep_data: null,
            pstep_note: "",
            pstep_data_aggiunta: null,
            pstep_data_modifica: null,
            pstep_data_val: null,
          });
        }
        if (this.preventivo.prev_tipo_detrazione === 'BA') {
          if (
            !this.stepPreventiviList.find((s) => s.pstep_numero === 5)
          ) {
            this.stepPreventiviList.push({
              pstep_id: 0,
              pstep_preventivo: Number.parseInt(this.id),
              pstep_step: 1,
              pstep_numero: 5,
              pstep_nome: "",
              pstep_tipo: "DETTAGLIO LAVORI",
              pstep_responsabile: "",
              pstep_allegato: "",
              pstep_validato: 0,
              pstep_data: null,
              pstep_note: "",
              pstep_data_aggiunta: null,
              pstep_data_modifica: null,
              pstep_data_val: null,
            });
          }
        }
        // sort in base a pstep_numero, mette prima il 100, poi il 101, poi 1 2 3 4
        if (this.preventivo.prev_tipo_detrazione === 'BA') {
          this.stepPreventiviList = [
            this.stepPreventiviList.find((s) => s.pstep_numero === 1),
            this.stepPreventiviList.find((s) => s.pstep_numero === 2),
            this.stepPreventiviList.find((s) => s.pstep_numero === 3),
            this.stepPreventiviList.find((s) => s.pstep_numero === 5),
            this.stepPreventiviList.find((s) => s.pstep_numero === 4),
            this.stepPreventiviList.find((s) => s.pstep_numero === 100),
            this.stepPreventiviList.find((s) => s.pstep_numero === 101),

          ];
        } else {
          this.stepPreventiviList = [
            this.stepPreventiviList.find((s) => s.pstep_numero === 1),
            this.stepPreventiviList.find((s) => s.pstep_numero === 2),
            this.stepPreventiviList.find((s) => s.pstep_numero === 3),
            this.stepPreventiviList.find((s) => s.pstep_numero === 4),
            this.stepPreventiviList.find((s) => s.pstep_numero === 100),
            this.stepPreventiviList.find((s) => s.pstep_numero === 101)
          ];
        }

        this.checkShowButtonProcedi();
        this.checkShowButtonApprova();
        this.refreshSerramentista(String(this.preventivo.prev_user))
      });
  }

  IconaCheck(validato: number): string {
    if (validato === 0) {
      return "pi pi-question-circle";
    } else if (validato === 1) {
      return "pi pi-check-circle";
    } else if (validato === 2) {
      return "pi pi-exclamation-circle";
    } else if (validato === 3) {
      return "pi pi-times-circle";
    }
  }

  ColorButton(validato: number): string {
    if (validato === 0) {
      return "p-button-secondary";
    } else if (validato === 1) {
      return "p-button-success";
    } else if (validato === 2) {
      return "p-button-danger";
    } else if (validato === 3) {
      return "p-button-warning";
    }
  }

  CheckVisibilita(doc: StepPreventivi): boolean {
    if (
      doc.pstep_numero === 100 ||
      doc.pstep_numero === 101
    ) {
      return this.ruoloUtente === "SA" || this.ruoloUtente === "AD";
    }
    return true;
  }

  checkShowButtonProcedi() {
    this.showButtonProcedi =
      this.stepPreventiviList.find((x) => x.pstep_numero === 1)
        .pstep_nome &&
      this.stepPreventiviList.find((x) => x.pstep_numero === 2)
        .pstep_nome &&
      this.stepPreventiviList.find((x) => x.pstep_numero === 3)
        .pstep_nome &&
      this.preventivo.prev_stato == 2;
  }

  checkShowButtonApprova() {
    const check_validato = this.stepPreventiviList.find(
      (x) => !x.pstep_validato
    );
    this.showButtonApprova =
      !check_validato && this.preventivo.prev_stato == 3;
  }

  OpenModalStato(idStep: number): void {
    this.display = true;
    this.cambiostatoStep = idStep;
  }

  DocCriticita(): void {
    this.CambioStatoObb(this.cambiostatoStep, 2);
  }

  DocNONCriticita(): void {
    this.CambioStatoObb(this.cambiostatoStep, 3);
  }

  DocNONVisionato(): void {
    this.CambioStatoObb(this.cambiostatoStep, 0);
  }

  DocValidato(): void {
    this.CambioStatoObb(this.cambiostatoStep, 1);
  }


  CambioDopoFileUpload(cambiostatoStep: number, validato: number): void {
    this.preventiviSrv.cambioStato(cambiostatoStep, validato).subscribe(
      (res) => {
        this.refreshStepPreventivi()
      },
      (error) => {
        this.messageSrv.add({
          key: "tst",
          severity: "error",
          summary: `Cambio Stato`,
          detail: "ERRORE NEL CAMBIO STATO",
          life: 8000 // Tempo in millisecondi (10 secondi)
        });
      }
    );

    this.display = false;
  }


  CambioStatoObb(cambiostatoStep: number, validato: number): void {
    this.preventiviSrv.cambioStato(cambiostatoStep, validato).subscribe(
      (res) => {
        this.messageSrv.add({
          key: "tst",
          severity: "info",
          summary: `Cambio Stato`,
          detail: "CAMBIO STATO AVVENUTO CON SUCCESSO",
          life: 8000 // Tempo in millisecondi (10 secondi)
        });
        this.refreshStepPreventivi();
      },
      (error) => {
        this.messageSrv.add({
          key: "tst",
          severity: "error",
          summary: `Cambio Stato`,
          detail: "ERRORE NEL CAMBIO STATO",
          life: 8000 // Tempo in millisecondi (10 secondi)
        });
      }
    );

    this.display = false;
  }

  NumberStep(numero: number): string {
    if (numero === 3) {
      return "1.102";
    }
    if (numero === 4) {
      return "1.3";
    }
    return "1." + numero;
  }

  DisabilitaAbilitaCheck(doc: StepPreventivi): boolean {
    //true -> disabilita
    //false -> abilita

    if (this.ruoloUtente === "SE") {
      return true;
    }

    return !doc.pstep_data_aggiunta;
  }

  delStepAdmin(id: string): void {
    this.stepDocService.delStepAdmin(id).subscribe((res) => {
      this.messageSrv.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "danger",
        summary: `Messaggio`,
        detail: "Step Eliminato Correttamente",
        life: 8000 // Tempo in millisecondi (10 secondi)
      });
      //this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');
    });
  }

  AddStep(doc: StepPreventivi) {
    this.preventiviSrv
      .addStep(
        String(doc.pstep_id),
        String(doc.pstep_preventivo),
        String(doc.pstep_step),
        String(doc.pstep_numero),
        String(""),
        String(doc.pstep_tipo),
        String(this.datePipe.transform(new Date(), "yyyy-MM-dd")),
        String(doc.pstep_note)
      )
      .subscribe(
        (res) => {
          this.refreshStepPreventivi();
        },
        (error) => {
          this.messageSrv.add({
            key: "tst",
            severity: "error",
            summary: `Aggiornamento Step`,
            detail: "Errore in fase di aggiornamento step",
            life: 8000 // Tempo in millisecondi (10 secondi)
          });
        }
      );

    this.checkShowButtonProcedi();
    if (doc.pstep_numero === 100) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["/SchedeIntervento", this.cliente.cl_id],
          {
            queryParams: {
              tab: 0,
            },
          }
        )
      );
      window.open("#" + url, "_blank");
    } else if (doc.pstep_numero === 101) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["/SchedeIntervento", this.cliente.cl_id],
          {
            queryParams: {
              tab: 1,
              abitazione: this.abitazione.ab_id,
            },
          }
        )
      );
      window.open("#" + url, "_blank");
    }
  }

  onUpload(event, docStep: number, numDoc: number, docTipo: string) {
    for (let file of event.files) {
      this.upFile = file;
    }
    this.uploadFileToActivity(docStep, numDoc, docTipo);
    this.checkShowButtonProcedi();
  }

  uploadFileToActivity(docStep: number, numDoc: number, docTipo: string) {
    let stepPreventivo = this.stepPreventiviList.find(
      (x) => x.pstep_numero == numDoc
    );

    this.preventiviSrv
      .upload(
        this.upFile,
        String(stepPreventivo.pstep_id),
        String(stepPreventivo.pstep_preventivo),
        String(stepPreventivo.pstep_step),
        String(stepPreventivo.pstep_numero),
        String(this.preventivo.prev_codice),
        String(stepPreventivo.pstep_tipo),
        String(this.datePipe.transform(new Date(), "yyyy-MM-dd")),
        String(stepPreventivo.pstep_note)
      )
      .subscribe(
        (res) => {
          this.messageSrv.add({
            key: "tst",
            severity: "info",
            summary: `File Caricato`,
            detail: res.nome_file,
            life: 8000 // Tempo in millisecondi (10 secondi)
          });
          // this.CambioDopoFileUpload(stepPreventivo.pstep_id, 0);
          this.refreshStepPreventivi();
        },
        (error) => {
          this.messageSrv.add({
            key: "tst",
            severity: "error",
            summary: `File Caricato`,
            detail: "ERRORE NEL CARICAMENTO ATTENDERE E RIPROVARE",
            life: 8000 // Tempo in millisecondi (10 secondi)
          });
        }
      );

    this.objFileUpload.ngOnDestroy();
  }

  invioEmailCriticita(): void {
    this.preventiviSrv
      .InvioEmailCriticità(Number(this.route.snapshot.paramMap.get("id")))
      .subscribe((res) => {
        this.messageSrv.add({
          key: "tst",
          severity: res.res === "ok" ? "success" : "danger",
          summary: `Messaggio`,
          detail: res.message,
          life: 8000 // Tempo in millisecondi (10 secondi)
        });
      });
  }

  invioEmailCaricamenti(): void {
    this.preventiviSrv
      .InvioEmailCaricamenti(
        Number(this.route.snapshot.paramMap.get("id"))
      )
      .subscribe((res) => {
        this.messageSrv.add({
          key: "tst",
          severity: res.res === "ok" ? "success" : "danger",
          summary: `Messaggio`,
          detail: res.message,
          life: 8000 // Tempo in millisecondi (10 secondi)
        });
        // this.refreshChat(this.id);
      });

  }

  async procedi(): Promise<void> {

    let costo: Number = 0

    if (this.preventivo.prev_praticaenea == false) {
      costo = Number(this.preventivo.lat_costo_fisso) - Number(this.preventivo.prev_costoenea)
    } else {
      costo = Number(this.preventivo.lat_costo_fisso)
    }

    if (((Number(this.creditiResidui) - Number(costo)) < 0) && this.creditiResidui != null) {
      this.msgService.add({
        key: "tst",
        severity: "error",
        summary: `Residuo Crediti`,
        detail: `Il credito attuale pari ad € ${this.creditiResidui} NON è Sufficiente per questo preventivo. E' necessario caricare un credito di almeno: € ${costo}`,
        life: 8000 // Tempo in millisecondi (10 secondi)\
      });
      return;
    }

    this.preventivo.prev_stato = 3;
    this.preventivo.prev_preventivo = JSON.parse(
      this.preventivo.prev_preventivo.toString()
    );
    this.preventiviSrv.salvaPreventivo(this.preventivo).subscribe((res) => {
      this.msgService.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "danger",
        summary: `Stato Preventivo`,
        detail:
          res.res === "ok"
            ? "Stato Salvato"
            : "Errore in fase di Salvataggio",
        life: 8000 // Tempo in millisecondi (10 secondi)
      });
      this.refreshStepPreventivi();
    
    });
  }

  async approva(): Promise<void> {
    this.preventivo.prev_stato = 4;
    this.preventivo.prev_preventivo = JSON.parse(
      this.preventivo.prev_preventivo.toString()
    );
    this.preventiviSrv.salvaPreventivo(this.preventivo).subscribe((res) => {
      this.msgService.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "danger",
        summary: `Offerta creata`,
        detail:
          res.res === "ok"
            ? "Offerta creata con successo"
            : "Errore in fase di Salvataggio",
        life: 8000 // Tempo in millisecondi (10 secondi)
      });
      this.refreshStepPreventivi();
    });
  }

  totParsed(): void {
    try {
      const prevPreventivoParsed = JSON.parse(this.preventivo.prev_preventivo.toString());
      return prevPreventivoParsed?.fattura?.totale?.tot;
    } catch (error) {
      return
    }

  }

  Titolarita(): string {

    if (this.preventivo.ab_titolarita == 1 || this.preventivo.ab_titolarita == 4 || this.preventivo.ab_titolarita == 7 || this.preventivo.ab_titolarita == 8 ||
      this.preventivo.ab_titolarita == 9 || this.preventivo.ab_titolarita == 10 || this.preventivo.ab_titolarita == 14
    ) { return "NO" }
    return "SI"

  }

  TitolaritaAutocerificazione(): boolean {

    if (this.preventivo.ab_titolarita == 2 || this.preventivo.ab_titolarita == 3 || this.preventivo.ab_titolarita == 5 || this.preventivo.ab_titolarita == 13 ||
      this.preventivo.ab_titolarita == 14 || this.preventivo.ab_titolarita == 17 || this.preventivo.ab_titolarita == 19 || this.preventivo.ab_titolarita == 20
    ) { return true }
    return false

  }


  inizioLavoriAdd(): void {
    let nuovoData: DateServizio;

    if (this.inizioLavoriPreventivo.length === 0) {
      // Se l'array è vuoto, crea un nuovo oggetto con i dati base
      nuovoData = {
        ds_tabella: 'preventivi',
        ds_campo_id: Number(this.id), // Assicurati che `id` sia un numero
        ds_descrizione: "DATA INZIO LAVORI",
        ds_data: this.inizioLavori
      };
    } else {
      // Se l'array contiene già dati, copia i dati esistenti e aggiorna `ds_data`
      nuovoData = {
        ...this.inizioLavoriPreventivo[0], // Copia tutto dall'oggetto esistente
        ds_data: this.inizioLavori // Sovrascrivi solo `ds_data` con il nuovo valore
      };
    }
    // Chiamata al servizio per aggiungere i dati
    this.dateservizioSrv.add(nuovoData).subscribe((res) => {
      this.msgService.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "error",
        summary: `Inizio Lavori`,
        detail: res.res === "ok"
          ? "Inizio lavori salvato"
          : "Errore in fase di salvataggio",
        life: 8000 // Tempo in millisecondi (8 secondi)
      });
    });
  }
}
