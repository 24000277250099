import { FormGroup } from "@angular/forms";
import * as _ from "lodash";
import { MessageService } from "primeng/api";
import { ChiavaValore } from "src/app/_interfaces/DataChiavaValore";
export class CalcoloPreventivoDD {
    preventivoForm: FormGroup;
    tipologiaForm: FormGroup;
    msgService: MessageService;
    tipo: "si_dd" | "ssco_dd" | "ba_dd_si" | "bc_dd" | "bc_dd_ai" | "ba_ai";
    ruoloUtente: string;
    data: any;

    values: {
        prev_imp_asseverabile: number;
        vendita_infissi: number;
        posa_opera: number;
        beni_significativi: number;
        altri_beni: number;
        altre_opere: number;
        prestazione_prof: number;
        fisica_giuridica: string;
        iva_offerta: string;
        asseverazione: string;
        imponibile_serramentista: number;
        zona_climatica: string;
        mq_installati: number;
        mq_installati_co: number;
        praticaenea: number;
        costoenea: number;
    };

    lat: {
        costo_fisso: number;
        asseverazione: number;
        asseverazione_perc: number;
    };

    calculated: {
        costo_pratica: number;
        nuovo_imponibile: number;
    };

    finanziaria: {
        tipofin: string,
        Nrate: number;
        importoRate: number;
        interessi: number;
        totale: number;
        saldo: number;
    }

    rispEnergetico: {
        tipo_materia: string;
        costomedio_energia: number;
        costomedio_gas: number;
        risparmio_energia: number;
        risparmio_gas: number;
        risparmio_annuo_stimato: number;
        risparmio_tot10anni: number;
        risparmio_tot30anni: number;
        Kg_di_CO2: number;
        consumomedio_automobile: number;
        nr_alberi: number;
    }

    importiVari: {
        imponibile_fornitore: number;
        imponibile_fornitura: number;
        costo_pratica: number;
        iva: number;
        tot_fattura: number;
        importo_detraibile: number;
        importo_non_detraibile: number;
        nuovo_imponibile: number;
    }


    fattura_SI: {
        posaInOpera_imponibile: number;
        posaInOpera_iva_10: number;
        posaInOpera_iva_22: number;
        posaInOpera_totale: number;

        valoreSchermatureSolari_imponibile: number;
        valoreSchermatureSolari_iva_10: number;
        valoreSchermatureSolari_iva_22: number;
        valoreSchermatureSolari_totale: number;

        valoreMeccanismi_imponibile: number;
        valoreMeccanismi_iva_10: number;
        valoreMeccanismi_iva_22: number;
        valoreMeccanismi_totale: number;

        valoreOscurantiTecniche_imponibile: number;
        valoreOscurantiTecniche_iva_10: number;
        valoreOscurantiTecniche_iva_22: number;
        valoreOscurantiTecniche_totale: number;

        opereComplementari_imponibile: number;
        opereComplementari_iva_10: number;
        opereComplementari_iva_22: number;
        opereComplementari_totale: number;

        prestazioni_imponibile: number;
        prestazioni_iva_10: number;
        prestazioni_iva_22: number;
        prestazioni_totale: number;

        totale_imponibile: number;
        totale_iva_10: number;
        totale_iva_22: number;
        totale_totale: number;
    };

    massimali_SI: {
        imponibile_fornitura: number;
        tot_mq_installati: number;

        massimale_mq_senza_oscurante: number;
        massimale_mq_con_oscurante: number;
        imponibile_max_senza_oscurante: number;
        imponibile_max_con_oscurante: number;
        imponibile_max: number;
        imponibile_non_detraibile: number;
        importo_detraibile_enea: number;
        recupero_10_anni: number;
    };

    fattura_SSCO: {
        posaInOpera_imponibile: number;
        posaInOpera_iva_10: number;
        posaInOpera_iva_22: number;
        posaInOpera_totale: number;

        valoreSchermatureSolari_imponibile: number;
        valoreSchermatureSolari_iva_10: number;
        valoreSchermatureSolari_iva_22: number;
        valoreSchermatureSolari_totale: number;

        valoreMeccanismi_imponibile: number;
        valoreMeccanismi_iva_10: number;
        valoreMeccanismi_iva_22: number;
        valoreMeccanismi_totale: number;

        valoreOscurantiTecniche_imponibile: number;
        valoreOscurantiTecniche_iva_10: number;
        valoreOscurantiTecniche_iva_22: number;
        valoreOscurantiTecniche_totale: number;

        opereComplementari_imponibile: number;
        opereComplementari_iva_10: number;
        opereComplementari_iva_22: number;
        opereComplementari_totale: number;

        prestazioni_imponibile: number;
        prestazioni_iva_10: number;
        prestazioni_iva_22: number;
        prestazioni_totale: number;

        totale_imponibile: number;
        totale_iva_10: number;
        totale_iva_22: number;
        totale_totale: number;
    };

    massimali_SSCO: {
        imponibile_fornitura: number;
        tot_mq_installati: number;
        massimale_mq: number;
        costo_mq_imponibile: number;
        imponibile_max: number;
        imponibile_non_detraibile: number;
        importo_detraibile_enea: number;
        recupero_10_anni: number;
    };
    preRE: ChiavaValore[];
    constructor(
        preventivoForm: FormGroup,
        tipologiaForm: FormGroup,
        msgService: MessageService,
        tipo: "si_dd" | "ssco_dd" | "ba_dd_si" | "bc_dd",
        ruoloUtente: string,
        data: any,
        preRE: ChiavaValore[]
    ) {
        this.preventivoForm = preventivoForm;
        this.tipologiaForm = tipologiaForm;
        this.tipo = tipo;
        this.data = data;
        this.msgService = msgService;
        this.ruoloUtente = ruoloUtente;
        this.preRE = preRE;
    }

    calcola() {
        this.retrieveFormValue();
        this.calcolaLat(this.tipo, this.data);
        this.calcalaCalculated();
        if (!this.checkValues()) {
            return false;
        }
        if (this.tipo === "si_dd" || this.tipo === "ba_dd_si" || this.tipo === "bc_dd") {
            this.calcolaFattura_SI();
            this.calcolaMassimali_SI();
            if (!this.checkValuesCalculated_SI()) {
                return false;
            }
            this.writeToForm_SI();
        } else if (this.tipo === "ssco_dd") {
            this.calcolaFattura_SSCO();
            this.calcolaMassimali_SSCO();
            if (!this.checkValuesCalculated_SSCO()) {
                return false;
            }
            this.writeToForm_SSCO();
        }
        this.success();
        return true;
    }

    calcolaserr_costi() {
        this.retrieveFormValue();
        this.calcolaLat(this.tipo, this.data);
        this.calcalaCalculated();
        if (!this.checkValues()) {
            return false;
        }
        if (this.tipo === "si_dd" || this.tipo === "ba_dd_si" || this.tipo === "bc_dd") {
            this.calcolaFattura_SI();
            this.calcolaMassimali_SI();
            if (!this.checkValuesCalculated_SI()) {
                return false;
            }
            this.writeToForm_SI();
        } else if (this.tipo === "ssco_dd") {
            this.calcolaFattura_SSCO();
            this.calcolaMassimali_SSCO();
            if (!this.checkValuesCalculated_SSCO()) {
                return false;
            }
            this.writeToForm_SSCO();
        }
        return { serr: this.values.imponibile_serramentista, cost: this.calculated.costo_pratica }
    }

    calcolaFinznziaria(impDetraibile: number) {
        this.finanziaria.totale = this.finanziaria.tipofin == 'NO' ? 0 : (Number(this.finanziaria.Nrate) * Number(this.finanziaria.importoRate)) + Number(this.finanziaria.interessi)
        this.finanziaria.saldo = this.finanziaria.tipofin == 'NO' ? 0 : Number(this.finanziaria.totale) - (Number(impDetraibile) / 2)
    }

    private retrieveFormValue() {
        // retrieve values
        const vendita_infissi = this.valueOrZero(this.preventivoForm.get("prev_vendita_infissi").value);
        const posa_opera = this.valueOrZero(this.preventivoForm.get("prev_posa_opera").value);
        const beni_significativi = this.valueOrZero(this.preventivoForm.get("prev_beni_significativi").value);
        const altri_beni = this.valueOrZero(this.preventivoForm.get("prev_altri_beni").value);
        const altre_opere = this.valueOrZero(this.preventivoForm.get("prev_altre_opere").value);
        const prestazione_prof = this.valueOrZero(this.preventivoForm.get("prev_prestazioni_prof").value);
        const fisica_giuridica = this.preventivoForm.get("lat_tipo").value;
        const iva_offerta = this.preventivoForm.get("prev_iva_offerta").value;
        const asseverazione = this.preventivoForm.get("prev_asseverazione").value;
        const praticaenea = this.preventivoForm.get("prev_praticaenea").value;
        const costoenea = this.preventivoForm.get("prev_costoenea").value;
        const imponibile_serramentista = this.tipo === "si_dd" || this.tipo === "ba_dd_si" || this.tipo === "bc_dd" ? vendita_infissi +
            posa_opera +
            altri_beni +
            altre_opere +
            prestazione_prof
            : this.tipo === "ssco_dd"
                ? vendita_infissi +
                beni_significativi +
                altri_beni +
                posa_opera +
                +altre_opere +
                prestazione_prof
                : 0;

        const prev_imp_asseverabile = this.preventivoForm.get("prev_imp_asseverazione").value;


        const zona_climatica = this.tipologiaForm.get("prev_zona_climatica").value;
        const mq_installati = this.valueOrZero(this.tipologiaForm.get("prev_mq_installati").value);
        const mq_installati_co = this.valueOrZero(this.tipologiaForm.get("prev_mq_installati_co").value);


        const tipofin = this.preventivoForm.controls["prev_preventiviAttr"].get("paf_fin_Tipo").value;
        const Nrate = this.preventivoForm.controls["prev_preventiviAttr"].get("paf_fin_numero_rate").value;
        const importoRate = this.preventivoForm.controls["prev_preventiviAttr"].get("paf_fin_importo_rate").value;
        const interessi = this.preventivoForm.controls["prev_preventiviAttr"].get("paf_fin_interessi").value;
        const totale = 0;
        const saldo = 0;

        const tipo_materia = this.preventivoForm.controls["prev_preventiviAttr"].get("paf_re_tipo").value;
        const costomedio_energia = this.preventivoForm.controls["prev_preventiviAttr"].get("paf_re_costomedio_elettrica").value;
        const costomedio_gas = this.preventivoForm.controls["prev_preventiviAttr"].get("paf_re_costomedio_gas").value;
        const risparmio_energia = 0;
        const risparmio_gas = 0;
        const risparmio_annuo_stimato = 0;
        const risparmio_tot10anni = 0;
        const risparmio_tot30anni = 0;
        const Kg_di_CO2 = 0;
        const consumomedio_automobile = 0;
        const nr_alberi = 0;

        const imponibile_fornitore = 0;
        const imponibile_fornitura = 0;
        const importo_detraibile = 0;
        const importo_non_detraibile = 0;
        const iva = 0;
        const tot_fattura = 0;
        const nuovo_imponibile = 0;
        const costo_pratica = 0;


        this.importiVari = {
            imponibile_fornitore,
            imponibile_fornitura,
            importo_detraibile,
            importo_non_detraibile,
            iva,
            tot_fattura,
            nuovo_imponibile,
            costo_pratica,
        }

        this.rispEnergetico = {
            tipo_materia,
            costomedio_energia,
            costomedio_gas,
            risparmio_energia,
            risparmio_gas,
            risparmio_annuo_stimato,
            risparmio_tot10anni,
            risparmio_tot30anni,
            Kg_di_CO2,
            consumomedio_automobile,
            nr_alberi,
        }

        this.finanziaria = {
            tipofin,
            Nrate,
            importoRate,
            interessi,
            totale,
            saldo,
        }

        this.values = {
            prev_imp_asseverabile,
            vendita_infissi,
            posa_opera,
            beni_significativi,
            altri_beni,
            altre_opere,
            prestazione_prof,
            fisica_giuridica,
            iva_offerta,
            asseverazione,
            imponibile_serramentista,
            zona_climatica,
            mq_installati,
            mq_installati_co,
            praticaenea,
            costoenea
        };
    }
    percDetrazione: number;


    private calcolaLat(tipo: string, data: any): void {
        let range1min: string;
        let range1max: string;
        let range2min: string;
        let range2max: string;
        let indice: string = "0";


        this.percDetrazione = this.preventivoForm.controls["prev_abitazione_principale"].value == 'ALTRO IMMOBILE' ? "36" : data.find((i) => i.ua_chiave === `${tipo}_detrazione`).ua_valore;

        range1min = data.find(
            (i) => i.ua_chiave === `${tipo}_1rangeMin`
        ).ua_valore;
        range1max = data.find(
            (i) => i.ua_chiave === `${tipo}_1rangeMax`
        ).ua_valore;
        range2min = data.find(
            (i) => i.ua_chiave === `${tipo}_2rangeMin`
        ).ua_valore;
        range2max = data.find(
            (i) => i.ua_chiave === `${tipo}_2rangeMax`
        ).ua_valore;

        if (
            _.inRange(
                this.values.imponibile_serramentista,
                Number(range1min),
                Number(range1max)
            )
        ) {
            indice = "1";
        } else if (
            _.inRange(
                this.values.imponibile_serramentista,
                Number(range2min),
                Number(range2max)
            )
        ) {
            indice = "2";
        } else {
            indice = "3";
        }

        // const percentualeImponibile = Number(this.getValoreByKey(`${ tipo }_${ indice }percImp`, data));

        if (this.preventivoForm.controls["lat_id"].value == "0") {
            this.preventivoForm.controls["lat_oneri_att"].setValue(
                this.getValoreByKey(`${tipo}_${indice}percOneri`, data)
            );
            // this.percentualeOneri = Number(this.getValoreByKey(`${ tipo }_${ indice }percOneri`, data));
        } else {
            // this.percentualeOneri = this.frmOfferta.controls.lat_oneri_att.value;
        }

        this.preventivoForm.controls["lat_imp"].setValue(this.getValoreByKey(`${tipo}_${indice}percImp`, data));
        this.preventivoForm.controls["lat_range_min"].setValue(this.getValoreByKey(`${tipo}_${indice}rangeMin`, data));
        this.preventivoForm.controls["lat_range_max"].setValue(this.getValoreByKey(`${tipo}_${indice}rangeMax`, data));
        this.preventivoForm.controls["lat_costo_fisso"].setValue(this.getValoreByKey(`${tipo}_${indice}costoFisso`, data));
        this.preventivoForm.controls["lat_costo_min"].setValue(this.getValoreByKey(`${tipo}_${indice}costoMin`, data));
        this.preventivoForm.controls["lat_costo_max"].setValue(this.getValoreByKey(`${tipo}_${indice}costoMax`, data));
        this.preventivoForm.controls["lat_costo_asseverazione"].setValue(this.getValoreByKey(`${tipo}_${indice}costoAssev`, data));
        this.preventivoForm.controls["lat_perc_asseverazione"].setValue(this.getValoreByKey(`${tipo}_${indice}percAssev`, data));
        this.preventivoForm.controls["lat_costo_visto"].setValue(this.getValoreByKey(`${tipo}_${indice}costoVisto`, data));
        this.preventivoForm.controls["lat_perc_visto"].setValue(this.getValoreByKey(`${tipo}_${indice}percVisto`, data));

        this.preventivoForm.controls["lat_detrazione"].setValue(
            this.preventivoForm.controls["prev_abitazione_principale"].value == 'ALTRO IMMOBILE' ? "36" : this.getValoreByKey(`${tipo}_detrazione`, data)

        );
        this.preventivoForm.controls["lat_oneri"].setValue(Number(this.getValoreByKey(`${tipo}_preventivatore`, data)));
        this.preventivoForm.controls["lat_cessionario"].setValue(Number(this.getValoreByKey(`${tipo}_cessionario`, data)));
        this.preventivoForm.controls["prev_cessionario"].setValue(Number(this.getValoreByKey(`${tipo}_cessionario`, data)));
        this.preventivoForm.controls["lat_chiusura_pratica"].setValue(this.getValoreByKey(`${tipo}_chiusuraPratica`, data));

        this.lat = {
            costo_fisso: Number(this.getValoreByKey(`${tipo}_${indice}costoFisso`, data)),
            asseverazione: Number(this.getValoreByKey(`${tipo}_${indice}costoAssev`, data)),
            asseverazione_perc: Number(this.getValoreByKey(`${tipo}_${indice}percAssev`, data)),
        };
    }

    private calcalaCalculated() {
        this.calcolaRisparmioEnergetico();
        let costo_pratica =
            this.values.asseverazione === "NO"
                ? this.lat.costo_fisso
                : this.values.asseverazione === "SI" &&
                    (this.values.imponibile_serramentista *
                        this.lat.asseverazione_perc) / 100 <= this.lat.asseverazione
                    ? this.lat.asseverazione + this.lat.costo_fisso
                    : this.lat.costo_fisso + (this.values.imponibile_serramentista * this.lat.asseverazione_perc) / 100;

        costo_pratica = this.values.praticaenea == 1 ? costo_pratica : costo_pratica - this.values.costoenea;

        const nuovo_imponibile =
            this.values.imponibile_serramentista + costo_pratica;


        this.importiVari.nuovo_imponibile = nuovo_imponibile;

        this.importiVari.costo_pratica = costo_pratica;

        this.importiVari.imponibile_fornitore = nuovo_imponibile - costo_pratica

        this.calculated = {
            costo_pratica,
            nuovo_imponibile,
        };
    }

    private checkValues(): boolean {
        // presente su excel ma nel codice è valido solo per si_dd
        if (
            (this.tipo === "si_dd" || this.tipo === "ba_dd_si" || this.tipo === "bc_dd") &&
            this.values.vendita_infissi < this.values.beni_significativi
        ) {
            this.error(
                "Vendita infissi non deve essere inferiore ai Beni significativi"
            );
            return false;
        }
        // presente su excel ma nel codice è valido solo per ssco_dd
        if (
            this.tipo === "ssco_dd" &&
            this.values.vendita_infissi === 0 &&
            this.values.beni_significativi === 0
        ) {
            this.error(
                "Beni significativi o Vendita infissi devono essere diversi da 0"
            );
            return false;
        }
        if (this.tipo === "ssco_dd" && this.values.posa_opera === 0) {
            this.error("Posa in opera deve essere diversa da 0");
            return false;
        }

        // Presente nell'excel
        if (this.values.posa_opera <= 0) {
            this.error("Posa in opera deve essere maggiore di 0");
            return false;
        }

        // Presente nell'excel
        if (
            this.values.asseverazione === "SI" &&
            this.values.altre_opere <= 0
        ) {
            this.error("Altre opere deve essere maggiore di 0");
            return false;
        }

        // presente nell'excel
        if (
            this.values.asseverazione === "SI" &&
            this.values.prestazione_prof <= 0
        ) {
            this.error("Prestazione professionale deve essere maggiore di 0");
            return false;
        }

        return true;
    }

    private checkValuesCalculated_SI(): boolean {
        if (
            this.values.posa_opera >
            this.values.mq_installati * 139.4 +
            this.values.mq_installati_co * 209.1
        ) {
            if (this.ruoloUtente === "SE") {
                this.error(
                    "VALORE DI POSA IN OPERA NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return false;
            } else {
                this.warning(
                    "VALORE DI POSA IN OPERA NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return true;
            }
        }

        if (
            this.values.altre_opere >
            this.values.mq_installati * 78.1 +
            this.values.mq_installati_co * 112.3
        ) {
            if (this.ruoloUtente === "SE") {
                this.error(
                    "VALORE DI ALTRE OPERE NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return false;
            } else {
                this.warning(
                    "VALORE DI ALTRE OPERE NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return true;
            }
        }

        const val = (this.massimali_SI.imponibile_max + this.values.mq_installati * 139.4 + this.values.mq_installati_co * 209.1 +
            this.values.mq_installati * 78.1 + this.values.mq_installati_co * 112.3) * (3.90817275638123 / 100);
        if (this.values.prestazione_prof > val) {
            if (this.ruoloUtente === "SE") {
                this.error(
                    "VALORE DI PRESTAZIONI PROFESSIONALI NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return false;
            } else {
                this.warning(
                    "VALORE DI PRESTAZIONI PROFESSIONALI NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return true;
            }
        }

        if (this.massimali_SI.imponibile_non_detraibile > 0) {
            this.warning(
                "IL PREVENTIVO PRESENTA UN IMPORTO NON DETRAIBILE PARI A € " +
                this.massimali_SI.imponibile_non_detraibile.toFixed(2) +
                ", SI CONSIGLIA DI VERIFICARE LA CORRETTEZZA DEI DATI"
            );
        }

        return true;
    }

    private checkValuesCalculated_SSCO(): boolean {
        if (
            this.values.posa_opera >
            this.values.mq_installati_co * 69.7 +
            this.values.mq_installati * 104.5
        ) {
            if (this.ruoloUtente === "SE") {
                this.error(
                    "VALORE DI POSA IN OPERA NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return false;
            } else {
                this.warning(
                    "VALORE DI POSA IN OPERA NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return true;
            }
        }

        if (
            this.values.altre_opere >
            this.values.mq_installati_co * 34.23 +
            this.values.mq_installati * 68.46
        ) {
            if (this.ruoloUtente === "SE") {
                this.error(
                    "VALORE DI ALTRE OPERE NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return false;
            } else {
                this.warning(
                    "VALORE DI ALTRE OPERE NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return true;
            }
        }

        if (
            this.values.prestazione_prof >
            (this.massimali_SSCO.imponibile_max +
                this.values.mq_installati_co * 69.7 +
                this.values.mq_installati * 104.5 +
                this.values.mq_installati_co * 34.23 +
                this.values.mq_installati * 68.46) *
            (3.90817275638123 / 100)
        ) {
            if (this.ruoloUtente === "SE") {
                this.error(
                    "VALORE DI PRESTAZIONI PROFESSIONALI NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return false;
            } else {
                this.warning(
                    "VALORE DI PRESTAZIONI PROFESSIONALI NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return true;
            }
        }

        if (this.massimali_SSCO.imponibile_non_detraibile > 0) {
            this.warning(
                "IL PREVENTIVO PRESENTA UN IMPORTO NON DETRAIBILE PARI A € " +
                this.massimali_SSCO.imponibile_non_detraibile.toFixed(2) +
                ", SI CONSIGLIA DI VERIFICARE LA CORRETTEZZA DEI DATI"
            );
        }

        return true;
    }

    private getValoreByKey = (key: string, array: any[]) =>
        array.find((i) => i.ua_chiave === key).ua_valore;

    calcolaMassimali_SI() {
        //*** CALCOLI */
        const imponibile_fornitura =
            this.calculated.nuovo_imponibile -
            this.values.posa_opera -
            this.values.altre_opere -
            this.values.prestazione_prof;

        this.importiVari.imponibile_fornitura = imponibile_fornitura;

        const tot_mq_installati =
            this.values.mq_installati + this.values.mq_installati_co;
        const trasmittanza_massima =
            this.values.zona_climatica === "A" ||
                this.values.zona_climatica === "B"
                ? "<=2,60"
                : this.values.zona_climatica === "C"
                    ? "<=1,75"
                    : this.values.zona_climatica === "D"
                        ? "<=1,67"
                        : this.values.zona_climatica === "E"
                            ? "<=1,30"
                            : this.values.zona_climatica === "F"
                                ? "<=1,0"
                                : "";
        const massimale_mq_senza_oscurante =
            this.values.mq_installati > 0 &&
                (this.values.zona_climatica === "A" ||
                    this.values.zona_climatica === "B" ||
                    this.values.zona_climatica === "C")
                ? 660
                : this.values.mq_installati > 0 &&
                    (this.values.zona_climatica === "D" ||
                        this.values.zona_climatica === "E" ||
                        this.values.zona_climatica === "F")
                    ? 780
                    : 0;
        const massimale_mq_con_oscurante =
            this.values.mq_installati_co > 0 &&
                (this.values.zona_climatica === "A" ||
                    this.values.zona_climatica === "B" ||
                    this.values.zona_climatica === "C")
                ? 780
                : this.values.mq_installati_co > 0 &&
                    (this.values.zona_climatica === "D" ||
                        this.values.zona_climatica === "E" ||
                        this.values.zona_climatica === "F")
                    ? 900
                    : 0;
        const imponibile_max_senza_oscurante = this.values.mq_installati * massimale_mq_senza_oscurante;
        const imponibile_max_con_oscurante = this.values.mq_installati_co * massimale_mq_con_oscurante;
        const imponibile_max = imponibile_max_senza_oscurante + imponibile_max_con_oscurante;

        const ImportoNONDetraibileEccedenteMassimanli: number =
            this.calcoloImportoNONDetraibileEccedenteMassimali_SI(imponibile_fornitura, imponibile_max)


        const imponibile_non_detraibile = this.calcoloImponibileNONDetraibile_SI(ImportoNONDetraibileEccedenteMassimanli);

        const importo_detraibile_enea =
            this.values.fisica_giuridica == 'fisica' ?
                this.fattura_SI.totale_totale - imponibile_non_detraibile :
                this.calculated.nuovo_imponibile - imponibile_non_detraibile;


        this.calcolaFinznziaria(importo_detraibile_enea);

        const recupero_10_anni = (importo_detraibile_enea * Number(this.percDetrazione)) / 100;


        this.importiVari.importo_detraibile = importo_detraibile_enea;
        this.importiVari.importo_non_detraibile = imponibile_non_detraibile;

        this.massimali_SI = {
            imponibile_fornitura,
            tot_mq_installati,
            massimale_mq_senza_oscurante,
            massimale_mq_con_oscurante,
            imponibile_max_senza_oscurante,
            imponibile_max_con_oscurante,
            imponibile_max,
            imponibile_non_detraibile,
            importo_detraibile_enea,
            recupero_10_anni,
        };

    }

    calcolaRisparmioEnergetico() {


        this.rispEnergetico.risparmio_energia = Number(this.calcoloRisparioStimatoEnergiaElettrica().toFixed(2));
        this.rispEnergetico.risparmio_gas = Number(this.calcoloRisparioStimatoGas().toFixed(2));
        this.rispEnergetico.risparmio_annuo_stimato =
            (this.rispEnergetico.costomedio_energia * this.rispEnergetico.risparmio_energia) +
            (this.rispEnergetico.costomedio_gas * this.rispEnergetico.risparmio_gas)
        this.rispEnergetico.risparmio_tot10anni = this.rispEnergetico.risparmio_annuo_stimato * 10
        this.rispEnergetico.risparmio_tot30anni = this.rispEnergetico.risparmio_annuo_stimato * 30

        this.rispEnergetico.Kg_di_CO2 = Math.round((((Number(this.preRE.find((x) => x.pre_chiave == "BA_KWE").pre_valore) * this.rispEnergetico.risparmio_energia) +
            (Number(this.preRE.find((x) => x.pre_chiave == "BA_SMC").pre_valore) * this.rispEnergetico.risparmio_gas)) * 10));
        this.rispEnergetico.consumomedio_automobile = Math.round((this.rispEnergetico.Kg_di_CO2 / Number(this.preRE.find((x) => x.pre_chiave == "BA_AUTO").pre_valore)) * 12)
        this.rispEnergetico.nr_alberi = Math.round(this.rispEnergetico.Kg_di_CO2 / Number(this.preRE.find((x) => x.pre_chiave == "BA_ALBERO").pre_valore))
    }

    calcoloRisparioStimatoEnergiaElettrica(): number {
        const D45: string = this.tipologiaForm.get("prev_zona_climatica").value;
        const D28: string = this.rispEnergetico.tipo_materia;
        const D48: number = Number(this.tipologiaForm.get("prev_mq_installati").value) + Number(this.tipologiaForm.get("prev_mq_installati_co").value);
        const G29: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_A").pre_valore);
        const G30: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_B").pre_valore);
        const G31: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_C").pre_valore);
        const G32: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_D").pre_valore);
        const G33: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_E").pre_valore);
        const G34: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_F").pre_valore);

        if (D45 === "A" && D28 === "Gas") {
            return D48 * (G29 / 1.95) * 0.2;
        } else if (D45 === "B" && D28 === "Gas") {
            return D48 * (G30 / 1.95) * 0.2;
        } else if (D45 === "C" && D28 === "Gas") {
            return D48 * (G31 / 1.95) * 0.2;
        } else if (D45 === "D" && D28 === "Gas") {
            return D48 * (G32 / 1.95) * 0.2;
        } else if (D45 === "E" && D28 === "Gas") {
            return D48 * (G33 / 1.95) * 0.2;
        } else if (D45 === "F" && D28 === "Gas") {
            return D48 * (G34 / 1.95) * 0.2;
        } else if (D45 === "A" && D28 === "Energia elettrica") {
            return D48 * (G29 / 1.95);
        } else if (D45 === "B" && D28 === "Energia elettrica") {
            return D48 * (G30 / 1.95);
        } else if (D45 === "C" && D28 === "Energia elettrica") {
            return D48 * (G31 / 1.95);
        } else if (D45 === "D" && D28 === "Energia elettrica") {
            return D48 * (G32 / 1.95);
        } else if (D45 === "E" && D28 === "Energia elettrica") {
            return D48 * (G33 / 1.95);
        } else if (D45 === "F" && D28 === "Energia elettrica") {
            return D48 * (G34 / 1.95);
        } else {
            return 0;
        }
    }


    calcoloRisparioStimatoGas(): number {

        const D45: string = this.tipologiaForm.get("prev_zona_climatica").value;
        const D28: string = this.rispEnergetico.tipo_materia;
        const D48: number = Number(this.tipologiaForm.get("prev_mq_installati").value) + Number(this.tipologiaForm.get("prev_mq_installati_co").value);
        const G29: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_A").pre_valore);
        const G30: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_B").pre_valore);
        const G31: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_C").pre_valore);
        const G32: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_D").pre_valore);
        const G33: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_E").pre_valore);
        const G34: number = Number(this.preRE.find((x) => x.pre_chiave == "RE_ZC_F").pre_valore);

        if (D45 === "A" && D28 === "Gas") {
            return D48 * (G29 / 10.69) * 0.8;
        } else if (D45 === "B" && D28 === "Gas") {
            return D48 * (G30 / 10.69) * 0.8;
        } else if (D45 === "C" && D28 === "Gas") {
            return D48 * (G31 / 10.69) * 0.8;
        } else if (D45 === "D" && D28 === "Gas") {
            return D48 * (G32 / 10.69) * 0.8;
        } else if (D45 === "E" && D28 === "Gas") {
            return D48 * (G33 / 10.69) * 0.8;
        } else if (D45 === "F" && D28 === "Gas") {
            return D48 * (G34 / 10.69) * 0.8;
        } else if (D28 === "Energia elettrica") {
            return 0;
        } else {
            return 0;
        }
    }


    calcoloImponibileNONDetraibile_SI(impDetrMaxMassimali: number): number {
        const F6: string = this.values.fisica_giuridica;
        const F10: string = this.values.asseverazione;
        const D14: number = this.calculated.nuovo_imponibile;
        const D16: number = Number(this.values.prev_imp_asseverabile);
        const D17: number = this.fattura_SI.totale_totale;
        const I23: number = this.fattura_SI.totale_iva_10;
        const J23: number = this.fattura_SI.totale_iva_22;
        const D35: number = impDetrMaxMassimali;
        const D18: number = this.tipo === 'ba_dd_si' ? 50000 : this.tipo === 'bc_dd' ? 96000 : 120000;
        if (F10 === "SI" && F6 === "giuridica" && D14 < D16 && D14 < D18) {
            return 0;
        } else if (F10 === "SI" && F6 === "giuridica" && D14 < D16 && D14 > D18) {
            return D14 - D18;
        } else if (F10 === "SI" && F6 === "giuridica" && D14 > D16 && D14 < D18) {
            return D14 - D16;
        } else if (F10 === "SI" && F6 === "giuridica" && D14 > D16 && D16 > D18) {
            return D14 - D18;
        } else if (F10 === "SI" && F6 === "fisica" && D14 > D16 && D16 + ((I23 + J23) / (D14 * D16)) < D18) {
            return (D14 - D16) + ((D14 - D16) / D14) * (I23 + J23);
        } else if (F10 === "SI" && F6 === "fisica" && D14 > D16 && D16 + ((I23 + J23) / (D14 * D16)) > D18) {
            return D17 - D18;
        } else if (F10 === "SI" && F6 === "fisica" && D14 < D16 && D17 < D18) {
            return 0;
        } else if (F10 === "SI" && F6 === "fisica" && D14 < D16 && D17 > D18) {
            return D17 - D18;
        } else if (F10 === "NO" && F6 === "giuridica" && D14 - D35 < D18) {
            return D35;
        } else if (F10 === "NO" && F6 === "giuridica" && D14 - D35 > D18) {
            return D35 + D14 - D18;
        } else if (F10 === "NO" && F6 === "fisica" && D17 - D35 < D18) {
            return D35;
        } else if (F10 === "NO" && F6 === "fisica" && D17 - D35 > D18) {
            return D35 + D17 - D18;
        } else {
            return 0;
        }
    }


    calcoloImportoNONDetraibileEccedenteMassimali_SI(imponibile_fornitura: number, imponibile_max: number) {
        const F6: string = this.values.fisica_giuridica;
        const D22: number = imponibile_fornitura;
        const D32: number = imponibile_max;
        const D13: number = this.calculated.nuovo_imponibile;
        const H20: number = this.fattura_SI.totale_iva_10;
        const I20: number = this.fattura_SI.totale_iva_22;


        return -(D22 - D32) > 0
            ? 0
            : F6 === "giuridica"
                ? D22 - D32
                : D32 < D22
                    ? (D22 - D32) + ((D22 - D32) / D13) * (H20 + I20)
                    : 0;
    }

    calcolaFattura_SI() {
        //**** Calcoli
        // 1 riga (Posa in opera)
        const posaInOpera_imponibile = this.values.posa_opera;
        const posaInOpera_iva_10 =
            this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                ? 0
                : this.values.iva_offerta === "4" ? posaInOpera_imponibile * 0.04 : posaInOpera_imponibile * 0.1;
        const posaInOpera_iva_22 =
            this.values.iva_offerta === "22"
                ? posaInOpera_imponibile * 0.22
                : 0;
        const posaInOpera_totale =
            posaInOpera_imponibile + posaInOpera_iva_10 + posaInOpera_iva_22;
        // 2 riga (Altri beni accessori)
        const valoreSchermatureSolari_imponibile =
            this.values.altri_beni === 0
                ? 0
                : this.values.altri_beni + this.calculated.costo_pratica / 2;
        const valoreSchermatureSolari_iva_10 =
            this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                ? 0
                : this.values.iva_offerta === "4" ? valoreSchermatureSolari_imponibile * 0.04 : valoreSchermatureSolari_imponibile * 0.1;
        const valoreSchermatureSolari_iva_22 =
            this.values.iva_offerta === "22" ? valoreSchermatureSolari_imponibile * 0.22
                : 0;
        const valoreSchermatureSolari_totale =
            valoreSchermatureSolari_imponibile +
            valoreSchermatureSolari_iva_10 +
            valoreSchermatureSolari_iva_22;

        // 4 riga (Valore di vendita infissi al 22%)
        const valoreMeccanismi_imponibile =
            (this.values.iva_offerta === "0" || this.values.iva_offerta === "22") && this.values.altri_beni === 0
                ? this.values.vendita_infissi + this.calculated.costo_pratica
                : this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                    ? this.values.vendita_infissi +
                    this.calculated.costo_pratica / 2
                    : this.values.iva_offerta === "10" ||
                        this.values.beni_significativi -
                        this.values.altri_beni -
                        (this.values.posa_opera +
                            this.values.vendita_infissi -
                            this.values.beni_significativi) -
                        this.calculated.costo_pratica -
                        this.values.altre_opere <=
                        0
                        ? 0
                        : this.values.beni_significativi -
                        this.values.altri_beni -
                        (this.values.posa_opera +
                            this.values.vendita_infissi -
                            this.values.beni_significativi) -
                        this.calculated.costo_pratica -
                        this.values.altre_opere;
        const valoreMeccanismi_iva_10 = 0;
        const valoreMeccanismi_iva_22 =
            this.values.iva_offerta === "0"
                ? 0
                : valoreMeccanismi_imponibile * 0.22;
        const valoreMeccanismi_totale =
            valoreMeccanismi_imponibile +
            valoreMeccanismi_iva_10 +
            valoreMeccanismi_iva_22;

        // 3 riga (Valore di vendita infissi al 10%)
        // TODO DA RIVEDERE
        const valoreOscurantiTecniche_imponibile =
            this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                ? 0
                : this.values.altri_beni === 0
                    ? this.values.vendita_infissi +
                    this.calculated.costo_pratica -
                    valoreMeccanismi_imponibile
                    : -valoreMeccanismi_imponibile +
                    this.values.vendita_infissi +
                    this.calculated.costo_pratica / 2;
        const valoreOscurantiTecniche_iva_10 = this.values.iva_offerta === "4" ? valoreOscurantiTecniche_imponibile * 0.04 : valoreOscurantiTecniche_imponibile * 0.1;
        const valoreOscurantiTecniche_iva_22 = 0;
        const valoreOscurantiTecniche_totale =
            valoreOscurantiTecniche_imponibile +
            valoreOscurantiTecniche_iva_10 +
            valoreOscurantiTecniche_iva_22;

        // 5 riga (Opere compl. all'installazione delle tecnologie)
        const opereComplementari_imponibile = this.values.altre_opere;
        const opereComplementari_iva_10 =
            this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                ? 0
                : this.values.iva_offerta === "4" ? opereComplementari_imponibile * 0.04 : opereComplementari_imponibile * 0.1;
        const opereComplementari_iva_22 =
            this.values.iva_offerta === "22"
                ? opereComplementari_imponibile * 0.22
                : 0;
        const opereComplementari_totale =
            opereComplementari_imponibile +
            opereComplementari_iva_10 +
            opereComplementari_iva_22;

        // 6 riga (Prestazioni professionali)
        const prestazioni_imponibile = this.values.prestazione_prof;
        const prestazioni_iva_10 = 0;
        const prestazioni_iva_22 =
            this.values.iva_offerta === "0" ? 0 : prestazioni_imponibile * 0.22;
        const prestazioni_totale =
            prestazioni_imponibile + prestazioni_iva_10 + prestazioni_iva_22;

        // 7 riga - totale
        const totale_imponibile =
            posaInOpera_imponibile +
            valoreSchermatureSolari_imponibile +
            valoreOscurantiTecniche_imponibile +
            valoreMeccanismi_imponibile +
            opereComplementari_imponibile +
            prestazioni_imponibile;
        const totale_iva_10 =
            posaInOpera_iva_10 +
            valoreSchermatureSolari_iva_10 +
            valoreOscurantiTecniche_iva_10 +
            valoreMeccanismi_iva_10 +
            opereComplementari_iva_10 +
            prestazioni_iva_10;
        const totale_iva_22 =
            posaInOpera_iva_22 +
            valoreSchermatureSolari_iva_22 +
            valoreOscurantiTecniche_iva_22 +
            valoreMeccanismi_iva_22 +
            opereComplementari_iva_22 +
            prestazioni_iva_22;
        const totale_totale =
            posaInOpera_totale +
            valoreSchermatureSolari_totale +
            valoreOscurantiTecniche_totale +
            valoreMeccanismi_totale +
            opereComplementari_totale +
            prestazioni_totale;

        this.importiVari.iva = totale_iva_10 + totale_iva_22;
        this.importiVari.tot_fattura = totale_totale;

        this.fattura_SI = {
            posaInOpera_imponibile,
            posaInOpera_iva_10,
            posaInOpera_iva_22,
            posaInOpera_totale,
            valoreSchermatureSolari_imponibile,
            valoreSchermatureSolari_iva_10,
            valoreSchermatureSolari_iva_22,
            valoreSchermatureSolari_totale,
            valoreOscurantiTecniche_imponibile,
            valoreOscurantiTecniche_iva_10,
            valoreOscurantiTecniche_iva_22,
            valoreOscurantiTecniche_totale,
            valoreMeccanismi_imponibile,
            valoreMeccanismi_iva_10,
            valoreMeccanismi_iva_22,
            valoreMeccanismi_totale,
            opereComplementari_imponibile,
            opereComplementari_iva_10,
            opereComplementari_iva_22,
            opereComplementari_totale,
            prestazioni_imponibile,
            prestazioni_iva_10,
            prestazioni_iva_22,
            prestazioni_totale,
            totale_imponibile,
            totale_iva_10,
            totale_iva_22,
            totale_totale,
        };
    }

    calcolo_Costo_Sostenuto_10anni(): number {
        return (Number(this.importiVari.importo_detraibile) + Number(this.importiVari.importo_non_detraibile) +
            Number(this.finanziaria.interessi))
            - (Number(this.massimali_SI.recupero_10_anni)) - Number(this.rispEnergetico.risparmio_tot10anni)
    }

    calcolo_Costo_Sostenuto_30anni(): number {
        return (Number(this.importiVari.importo_detraibile) + Number(this.importiVari.importo_non_detraibile) +
            Number(this.finanziaria.interessi))
            - (Number(this.massimali_SI.recupero_10_anni)) - Number(this.rispEnergetico.risparmio_tot30anni)
    }

    writeToForm_SI() {
        // Scrivo nel form fattura
        this.preventivoForm.controls["prev_preventivo"].get("posaOperaImponibile").setValue(this.fattura_SI.posaInOpera_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("posaOpera10").setValue(this.fattura_SI.posaInOpera_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("posaOpera22").setValue(this.fattura_SI.posaInOpera_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("posaOperaTot").setValue(this.fattura_SI.posaInOpera_totale);

        this.preventivoForm.controls["prev_preventivo"].get("altibeniaccImponibile").setValue(this.fattura_SI.valoreSchermatureSolari_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("altibeniacc10").setValue(this.fattura_SI.valoreSchermatureSolari_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("altibeniacc22").setValue(this.fattura_SI.valoreSchermatureSolari_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("altibeniaccTot").setValue(this.fattura_SI.valoreSchermatureSolari_totale);
        this.preventivoForm.controls["prev_preventivo"].get("valore10Imponibile").setValue(this.fattura_SI.valoreOscurantiTecniche_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("valore1010").setValue(this.fattura_SI.valoreOscurantiTecniche_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("valore1022").setValue(this.fattura_SI.valoreOscurantiTecniche_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("valore10Tot").setValue(this.fattura_SI.valoreOscurantiTecniche_totale);
        this.preventivoForm.controls["prev_preventivo"].get("valore22Imponibile").setValue(this.fattura_SI.valoreMeccanismi_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("valore2210").setValue(this.fattura_SI.valoreMeccanismi_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("valore2222").setValue(this.fattura_SI.valoreMeccanismi_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("valore22Tot").setValue(this.fattura_SI.valoreMeccanismi_totale);
        this.preventivoForm.controls["prev_preventivo"].get("altreOpereImponibile").setValue(this.fattura_SI.opereComplementari_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("altreOpere10").setValue(this.fattura_SI.opereComplementari_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("altreOpere22").setValue(this.fattura_SI.opereComplementari_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("altreOpereTot").setValue(this.fattura_SI.opereComplementari_totale);
        this.preventivoForm.controls["prev_preventivo"].get("prestProfImponibile").setValue(this.fattura_SI.prestazioni_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("prestProf10").setValue(this.fattura_SI.prestazioni_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("prestProf22").setValue(this.fattura_SI.prestazioni_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("prestProfTot").setValue(this.fattura_SI.prestazioni_totale);
        this.preventivoForm.controls["prev_preventivo"].get("ftImponibile").setValue(this.fattura_SI.totale_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("ft10").setValue(this.fattura_SI.totale_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("ft22").setValue(this.fattura_SI.totale_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("ftTot").setValue(this.fattura_SI.totale_totale);

        // scrivo nel form massimali
        this.preventivoForm.controls["prev_preventivo"].get("imponibileFornitura").setValue(this.massimali_SI.imponibile_fornitura);
        this.preventivoForm.controls["prev_preventivo"].get("massimaleMQdaApplicare").setValue(this.massimali_SI.massimale_mq_senza_oscurante);
        this.preventivoForm.controls["prev_preventivo"].get("massimaleMQdaApplicare_CON_CO").setValue(this.massimali_SI.massimale_mq_con_oscurante);
        this.preventivoForm.controls["prev_preventivo"].get("imponibileMassimoDetraibile").setValue(this.massimali_SI.imponibile_max_senza_oscurante);
        this.preventivoForm.controls["prev_preventivo"].get("imponibileMassimoDetraibile_CON_CO").setValue(this.massimali_SI.imponibile_max_con_oscurante);
        this.preventivoForm.controls["prev_preventivo"].get("imponibileMassimoDetraibile_Totale").setValue(this.massimali_SI.imponibile_max);
        this.preventivoForm.controls["prev_preventivo"].get("costoMQImponibile").setValue(this.massimali_SI.imponibile_max);
        this.preventivoForm.controls["prev_preventivo"].get("importoNonDetraibile").setValue(this.massimali_SI.imponibile_non_detraibile);

        // scrivo preventiviAttr PAF - FINANZIAMENTO
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_fin_totale").setValue(this.finanziaria.totale);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_fin_saldo").setValue(this.finanziaria.saldo);
        // scrivo preventiviAttr PAF - RISPARMIO ENERGETICO
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_re_risparmio_elettrica").setValue(this.rispEnergetico.risparmio_energia);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_re_risparmio_gas").setValue(this.rispEnergetico.risparmio_gas);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_re_risparmio_annuo").setValue(this.rispEnergetico.risparmio_annuo_stimato);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_re_risparmio_tot10anni").setValue(this.rispEnergetico.risparmio_tot10anni);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_re_risparmio_tot30anni").setValue(this.rispEnergetico.risparmio_tot30anni);
        // scrivo preventiviAttr PAF - BENEFICIO AMBIENTALE
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_ba_co2_non_emessa").setValue(this.rispEnergetico.Kg_di_CO2);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_ba_consumo_auto").setValue(this.rispEnergetico.consumomedio_automobile);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_ba_nuovi_alberi").setValue(this.rispEnergetico.nr_alberi);
        // scrivo preventiviAttr PAF - IMPORTIVARI
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_imp_fornitore").setValue(this.importiVari.imponibile_fornitore);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_imp_fornitura").setValue(this.importiVari.imponibile_fornitura);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_costo_pratica").setValue(this.importiVari.costo_pratica);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_nuovo_imponibile").setValue(this.importiVari.nuovo_imponibile);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_iva").setValue(this.importiVari.iva);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_tot_fattura").setValue(this.importiVari.tot_fattura);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_imp_detraibile").setValue(this.importiVari.importo_detraibile);
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_imp_non_detraibile").setValue(this.importiVari.importo_non_detraibile);

        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_tot_costo_10anni").setValue(this.calcolo_Costo_Sostenuto_10anni());
        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_tot_costo_30anni").setValue(this.calcolo_Costo_Sostenuto_30anni());


        this.preventivoForm.controls["prev_imp_detraibile_enea"].setValue(this.massimali_SI.importo_detraibile_enea);
        this.preventivoForm.controls["prev_max_detraibile"].setValue(this.massimali_SI.imponibile_non_detraibile);
        this.preventivoForm.controls["prev_recupero_fiscale_anno"].setValue(this.massimali_SI.recupero_10_anni);
    }

    clearForm_SI() { }

    calcolaMassimali_SSCO() {
        //*** CALCOLI */
        const imponibile_fornitura =
            this.calculated.nuovo_imponibile -
            this.values.posa_opera -
            this.values.altre_opere -
            this.values.prestazione_prof;
        const tot_mq_installati =
            this.values.mq_installati + this.values.mq_installati_co;
        const massimale_mq = 276;
        const costo_mq_imponibile =
            imponibile_fornitura /
            (this.values.mq_installati + this.values.mq_installati_co);
        const imponibile_max = tot_mq_installati * massimale_mq;
        const ImportoNONDetraibileEccedenteMassimanli: number =
            this.calcoloImportoNONDetraibileEccedenteMassimali_SSCO(imponibile_fornitura, imponibile_max)

        const imponibile_non_detraibile = this.calcoloImponibileNONDetraibile_SSCO(ImportoNONDetraibileEccedenteMassimanli);

        const importo_detraibile_enea =
            this.values.fisica_giuridica == 'fisica' ?
                this.fattura_SSCO.totale_totale - imponibile_non_detraibile :
                this.calculated.nuovo_imponibile - imponibile_non_detraibile;

        this.calcolaFinznziaria(importo_detraibile_enea);

        const recupero_10_anni = (importo_detraibile_enea * Number(this.percDetrazione)) / 100;

        this.massimali_SSCO = {
            imponibile_fornitura,
            tot_mq_installati,
            massimale_mq,
            costo_mq_imponibile,
            imponibile_max,
            imponibile_non_detraibile,
            importo_detraibile_enea,
            recupero_10_anni,
        };
    }


    calcoloImponibileNONDetraibile_SSCO(impDetrMaxMassimali: number): number {
        const F6: string = this.values.fisica_giuridica;
        const F10: string = this.values.asseverazione;
        const D14: number = this.calculated.nuovo_imponibile;
        const D16: number = this.values.prev_imp_asseverabile;
        const D17: number = this.fattura_SSCO.totale_totale;
        const I23: number = this.fattura_SSCO.totale_iva_10;
        const J23: number = this.fattura_SSCO.totale_iva_22;
        const D35: number = impDetrMaxMassimali;
        const D18: number = 120000

        if (F10 === "SI" && F6 === "giuridica" && D14 < D16 && D14 < D18) {
            return 0;
        } else if (F10 === "SI" && F6 === "giuridica" && D14 < D16 && D14 > D18) {
            return D14 - D18;
        } else if (F10 === "SI" && F6 === "giuridica" && D14 > D16 && D14 < D18) {
            return D14 - D16;
        } else if (F10 === "SI" && F6 === "giuridica" && D14 > D16 && D16 > D18) {
            return D14 - D18;
        } else if (F10 === "SI" && F6 === "fisica" && D14 > D16 && D16 + ((I23 + J23) / (D14 * D16)) < D18) {
            return (D14 - D16) + ((D14 - D16) / D14) * (I23 + J23);
        } else if (F10 === "SI" && F6 === "fisica" && D14 > D16 && D16 + ((I23 + J23) / (D14 * D16)) > D18) {
            return D17 - D18;
        } else if (F10 === "SI" && F6 === "fisica" && D14 < D16 && D17 < D18) {
            return 0;
        } else if (F10 === "SI" && F6 === "fisica" && D14 < D16 && D17 > D18) {
            return D17 - D18;
        } else if (F10 === "NO" && F6 === "giuridica" && D14 - D35 < D18) {
            return D35;
        } else if (F10 === "NO" && F6 === "giuridica" && D14 - D35 > D18) {
            return D35 + D14 - D18;
        } else if (F10 === "NO" && F6 === "fisica" && D17 - D35 < D18) {
            return D35;
        } else if (F10 === "NO" && F6 === "fisica" && D17 - D35 > D18) {
            return D35 + D17 - D18;
        } else {
            return 0;
        }
    }


    calcoloImportoNONDetraibileEccedenteMassimali_SSCO(imponibile_fornitura: number, imponibile_max: number) {
        const F6: string = this.values.fisica_giuridica;
        const D22: number = imponibile_fornitura;
        const D32: number = imponibile_max;
        const D13: number = this.calculated.nuovo_imponibile;
        const H20: number = this.fattura_SSCO.totale_iva_10;
        const I20: number = this.fattura_SSCO.totale_iva_22;


        return -(D22 - D32) > 0
            ? 0
            : F6 === "giuridica"
                ? D22 - D32
                : D32 < D22
                    ? (D22 - D32) + ((D22 - D32) / D13) * (H20 + I20)
                    : 0;
    }

    calcolaFattura_SSCO() {
        //**** Calcoli
        // 1 riga (Posa in opera)
        const posaInOpera_imponibile = this.values.posa_opera;
        const posaInOpera_iva_10 =
            this.values.iva_offerta === "10" ? posaInOpera_imponibile * 0.1 : 0;
        const posaInOpera_iva_22 =
            this.values.iva_offerta === "22"
                ? posaInOpera_imponibile * 0.22
                : 0;
        const posaInOpera_totale =
            posaInOpera_imponibile + posaInOpera_iva_10 + posaInOpera_iva_22;
        // 2 riga (Altri beni accessori)
        const valoreSchermatureSolari_imponibile =
            this.values.vendita_infissi === 0
                ? 0
                : this.values.altri_beni === 0 &&
                    this.values.beni_significativi === 0
                    ? this.values.vendita_infissi + this.calculated.costo_pratica
                    : this.values.vendita_infissi +
                    this.calculated.costo_pratica /
                    (this.values.altri_beni === 0 ||
                        this.values.beni_significativi === 0
                        ? 2
                        : 3);
        const valoreSchermatureSolari_iva_10 =
            this.values.iva_offerta === "10"
                ? valoreSchermatureSolari_imponibile * 0.1
                : 0;
        const valoreSchermatureSolari_iva_22 =
            this.values.iva_offerta === "22"
                ? valoreSchermatureSolari_imponibile * 0.22
                : 0;
        const valoreSchermatureSolari_totale =
            valoreSchermatureSolari_imponibile +
            valoreSchermatureSolari_iva_10 +
            valoreSchermatureSolari_iva_22;

        // 4 riga (Valore di vendita infissi al 22%)
        const valoreMeccanismi_imponibile =
            this.values.altri_beni === 0
                ? 0
                : this.values.altri_beni +
                this.calculated.costo_pratica /
                (this.values.vendita_infissi === 0 ||
                    this.values.beni_significativi === 0
                    ? 2
                    : 3);
        const valoreMeccanismi_iva_10 =
            this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                ? 0
                : valoreMeccanismi_imponibile * 0.1;
        const valoreMeccanismi_iva_22 =
            this.values.iva_offerta === "22"
                ? valoreMeccanismi_imponibile * 0.22
                : 0;
        const valoreMeccanismi_totale =
            valoreMeccanismi_imponibile +
            valoreMeccanismi_iva_10 +
            valoreMeccanismi_iva_22;

        // 3 riga (Valore di vendita infissi al 10%)
        const valoreOscurantiTecniche_imponibile =
            this.values.beni_significativi === 0
                ? 0
                : this.values.altri_beni === 0 &&
                    this.values.vendita_infissi === 0
                    ? this.values.beni_significativi + this.calculated.costo_pratica
                    : this.values.altri_beni === 0 ||
                        this.values.vendita_infissi === 0
                        ? this.values.beni_significativi +
                        this.calculated.costo_pratica / 2
                        : this.values.beni_significativi +
                        this.calculated.costo_pratica / 3;
        const valoreOscurantiTecniche_iva_10 =
            this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                ? 0
                : valoreOscurantiTecniche_imponibile * 0.1;
        const valoreOscurantiTecniche_iva_22 =
            this.values.iva_offerta === "22"
                ? valoreOscurantiTecniche_imponibile * 0.22
                : 0;
        const valoreOscurantiTecniche_totale =
            valoreOscurantiTecniche_imponibile +
            valoreOscurantiTecniche_iva_10 +
            valoreOscurantiTecniche_iva_22;

        // 5 riga (Opere compl. all'installazione delle tecnologie)
        const opereComplementari_imponibile = this.values.altre_opere;
        const opereComplementari_iva_10 =
            this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                ? 0
                : opereComplementari_imponibile * 0.1;
        const opereComplementari_iva_22 =
            this.values.iva_offerta === "22"
                ? opereComplementari_imponibile * 0.22
                : 0;
        const opereComplementari_totale =
            opereComplementari_imponibile +
            opereComplementari_iva_10 +
            opereComplementari_iva_22;

        // 6 riga (Prestazioni professionali)
        const prestazioni_imponibile = this.values.prestazione_prof;
        const prestazioni_iva_10 = 0;
        const prestazioni_iva_22 =
            this.values.iva_offerta === "0" ? 0 : prestazioni_imponibile * 0.22;
        const prestazioni_totale =
            prestazioni_imponibile + prestazioni_iva_10 + prestazioni_iva_22;

        // 7 riga - totale
        const totale_imponibile =
            posaInOpera_imponibile +
            valoreSchermatureSolari_imponibile +
            valoreOscurantiTecniche_imponibile +
            valoreMeccanismi_imponibile +
            opereComplementari_imponibile +
            prestazioni_imponibile;
        const totale_iva_10 =
            posaInOpera_iva_10 +
            valoreSchermatureSolari_iva_10 +
            valoreOscurantiTecniche_iva_10 +
            valoreMeccanismi_iva_10 +
            opereComplementari_iva_10 +
            prestazioni_iva_10;
        const totale_iva_22 =
            posaInOpera_iva_22 +
            valoreSchermatureSolari_iva_22 +
            valoreOscurantiTecniche_iva_22 +
            valoreMeccanismi_iva_22 +
            opereComplementari_iva_22 +
            prestazioni_iva_22;
        const totale_totale =
            posaInOpera_totale +
            valoreSchermatureSolari_totale +
            valoreOscurantiTecniche_totale +
            valoreMeccanismi_totale +
            opereComplementari_totale +
            prestazioni_totale;

        this.fattura_SSCO = {
            posaInOpera_imponibile,
            posaInOpera_iva_10,
            posaInOpera_iva_22,
            posaInOpera_totale,
            valoreSchermatureSolari_imponibile,
            valoreSchermatureSolari_iva_10,
            valoreSchermatureSolari_iva_22,
            valoreSchermatureSolari_totale,
            valoreOscurantiTecniche_imponibile,
            valoreOscurantiTecniche_iva_10,
            valoreOscurantiTecniche_iva_22,
            valoreOscurantiTecniche_totale,
            valoreMeccanismi_imponibile,
            valoreMeccanismi_iva_10,
            valoreMeccanismi_iva_22,
            valoreMeccanismi_totale,
            opereComplementari_imponibile,
            opereComplementari_iva_10,
            opereComplementari_iva_22,
            opereComplementari_totale,
            prestazioni_imponibile,
            prestazioni_iva_10,
            prestazioni_iva_22,
            prestazioni_totale,
            totale_imponibile,
            totale_iva_10,
            totale_iva_22,
            totale_totale,
        };
    }

    writeToForm_SSCO() {
        this.preventivoForm.controls["prev_preventivo"].get("posaOperaImponibile").setValue(this.fattura_SSCO.posaInOpera_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("posaOpera10").setValue(this.fattura_SSCO.posaInOpera_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("posaOpera22").setValue(this.fattura_SSCO.posaInOpera_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("posaOperaTot").setValue(this.fattura_SSCO.posaInOpera_totale);
        this.preventivoForm.controls["prev_preventivo"].get("altibeniaccImponibile").setValue(this.fattura_SSCO.valoreSchermatureSolari_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("altibeniacc10").setValue(this.fattura_SSCO.valoreSchermatureSolari_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("altibeniacc22").setValue(this.fattura_SSCO.valoreSchermatureSolari_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("altibeniaccTot").setValue(this.fattura_SSCO.valoreSchermatureSolari_totale);
        this.preventivoForm.controls["prev_preventivo"].get("valore10Imponibile").setValue(this.fattura_SSCO.valoreOscurantiTecniche_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("valore1010").setValue(this.fattura_SSCO.valoreOscurantiTecniche_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("valore1022").setValue(this.fattura_SSCO.valoreOscurantiTecniche_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("valore10Tot").setValue(this.fattura_SSCO.valoreOscurantiTecniche_totale);
        this.preventivoForm.controls["prev_preventivo"].get("valore22Imponibile").setValue(this.fattura_SSCO.valoreMeccanismi_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("valore2210").setValue(this.fattura_SSCO.valoreMeccanismi_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("valore2222").setValue(this.fattura_SSCO.valoreMeccanismi_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("valore22Tot").setValue(this.fattura_SSCO.valoreMeccanismi_totale);
        this.preventivoForm.controls["prev_preventivo"].get("altreOpereImponibile").setValue(this.fattura_SSCO.opereComplementari_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("altreOpere10").setValue(this.fattura_SSCO.opereComplementari_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("altreOpere22").setValue(this.fattura_SSCO.opereComplementari_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("altreOpereTot").setValue(this.fattura_SSCO.opereComplementari_totale);
        this.preventivoForm.controls["prev_preventivo"].get("prestProfImponibile").setValue(this.fattura_SSCO.prestazioni_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("prestProf10").setValue(this.fattura_SSCO.prestazioni_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("prestProf22").setValue(this.fattura_SSCO.prestazioni_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("prestProfTot").setValue(this.fattura_SSCO.prestazioni_totale);
        this.preventivoForm.controls["prev_preventivo"].get("ftImponibile").setValue(this.fattura_SSCO.totale_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("ft10").setValue(this.fattura_SSCO.totale_iva_10);
        this.preventivoForm.controls["prev_preventivo"].get("ft22").setValue(this.fattura_SSCO.totale_iva_22);
        this.preventivoForm.controls["prev_preventivo"].get("ftTot").setValue(this.fattura_SSCO.totale_totale);
        this.preventivoForm.controls["prev_preventivo"].get("imponibileFornitura").setValue(this.massimali_SSCO.imponibile_fornitura);
        this.preventivoForm.controls["prev_preventivo"].get("massimaleMQdaApplicare").setValue(this.massimali_SSCO.massimale_mq);
        this.preventivoForm.controls["prev_preventivo"].get("massimaleMQdaApplicare_CON_CO").setValue(this.massimali_SSCO.massimale_mq);
        this.preventivoForm.controls["prev_preventivo"].get("imponibileMassimoDetraibile").setValue(this.massimali_SSCO.imponibile_max);
        this.preventivoForm.controls["prev_preventivo"].get("imponibileMassimoDetraibile_CON_CO").setValue(this.massimali_SSCO.imponibile_max);
        this.preventivoForm.controls["prev_preventivo"].get("imponibileMassimoDetraibile_Totale").setValue(this.massimali_SSCO.imponibile_max);
        this.preventivoForm.controls["prev_preventivo"].get("costoMQImponibile").setValue(this.massimali_SSCO.costo_mq_imponibile);
        this.preventivoForm.controls["prev_preventivo"].get("importoNonDetraibile").setValue(this.massimali_SSCO.imponibile_non_detraibile);
        this.preventivoForm.controls["prev_imp_detraibile_enea"].setValue(this.massimali_SSCO.importo_detraibile_enea);
        this.preventivoForm.controls["prev_max_detraibile"].setValue(this.massimali_SSCO.imponibile_non_detraibile);
        this.preventivoForm.controls["prev_recupero_fiscale_anno"].setValue(this.massimali_SSCO.recupero_10_anni);

        this.preventivoForm.controls["prev_preventiviAttr"].get("paf_costo_pratica").setValue(this.importiVari.costo_pratica);

    }

    private valueOrZero(value: any) {
        if (value === "") return 0;
        if (value === null || value === undefined) return 0;
        return Number.parseFloat(value);
    }

    private error(err: string) {
        this.msgService.add({
            key: "tst",
            severity: "error",
            summary: "Errore",
            detail: err,
            life: 8000 // Tempo in millisecondi (10 secondi)
        });
    }
    private warning(err: string) {
        this.msgService.add({
            key: "tst",
            severity: "warn",
            summary: "Attenzione",
            detail: err,
            life: 8000 // Tempo in millisecondi (10 secondi)
        });
    }
    private success() {
        this.msgService.add({
            key: "tst",
            severity: "success",
            summary: "Calcola preventivo",
            detail: "Il preventivo è stato calcolato con successo",
            life: 8000 // Tempo in millisecondi (10 secondi)
        });
    }
}
