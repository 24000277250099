import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UploadService } from 'src/app/_services/upload.service'
import { Offerta, Preventivo } from 'src/app/_interfaces/lavori';
import { Abitazioni } from 'src/app/_interfaces/abitazioni';
import { LavoriService } from 'src/app/_services/lavori.service';
import { ActivatedRoute, Data } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DocLavoro, StepLavoro } from 'src/app/_interfaces/step-lavori';
import { Messaggi, MessaggiAdmin } from 'src/app/_interfaces/messaggi';
import { StepLavoriService } from 'src/app/_services/step-lavori.service';
import * as _ from 'lodash';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbitazioniService } from 'src/app/_services/abitazioni.service';
import { Catastale } from 'src/app/_interfaces/list-type';
import { ListTypeService } from 'src/app/_services/list-type.service';
import { FileUpload } from 'primeng/fileupload';
import { UtcDatePipe } from "src/app/_pipes/utc-date.pipe";
import { forkJoin } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HtmlPreviewStep5Component } from '../html-preview-step5/html-preview-step5.component';
import { MessaggiPreventiviService } from 'src/app/_services/messaggi-preventivi.service';
import { ChatLavoriComponent } from 'src/app/_components/_shared/chat-lavori/chat-lavori.component';
import { SerramentistaService } from 'src/app/_services/serramentista.service';
import { Serramentista } from 'src/app/_interfaces/serramentista';
import { el } from 'date-fns/locale';

@Component({
    selector: 'app-lavori-step-new',
    templateUrl: './lavori-step-new.component.html',
    styleUrls: ['./lavori-step-new.component.scss']
})

export class LavoriStepNewComponent implements OnInit {

    @ViewChild(ChatLavoriComponent) chatLavoriComponent!: ChatLavoriComponent;
    serramentista: Serramentista;
    upFile: File;
    offerta: Offerta;
    abitazione: Abitazioni = {};

    chatPreventivi: Messaggi[];
    chatAdminPreventivi: MessaggiAdmin[]

    categoriaCatastale: Catastale[];

    contributoSpettante: number;

    importoStep281: number = 0;
    importoStep282: number = 0;
    importoStep283: number = 0;

    importoStep271: number = 0;
    importoStep272: number = 0;
    importoStep273: number = 0;

    dataStep281: number = 0;
    dataStep282: number = 0;
    dataStep283: number = 0;

    dataStep271: number = 0;
    dataStep272: number = 0;
    dataStep273: number = 0;


    DisabilitaSte282: boolean = true;
    DisabilitaSte283: boolean = true;

    DisabilitaSte272: boolean = true;
    DisabilitaSte273: boolean = true;

    step: StepLavoro = {};
    documentiLavoro: DocLavoro[] = null;
    documentiStepRiga: DocLavoro[] = null;

    cambiostatoStep: number = 0;
    cambiostatoDoc: number = 0;
    cambiostatoGestoreTudor: string = "";
    frmAbitazione: FormGroup;

    ruoloUtente: string = "SE";
    idUtente: string;

    messageChatPreventivo: string;
    messageChatAdminPreventivo: string;

    modalAbitazione: boolean;

    doc_41_anno: number = null;
    doc_41_importo: number = null;
    doc_43_anno: number = null;
    doc_43_importo: number = null;

    doc_26_caricato: boolean = false;
    doc_25_caricato: boolean = false;

    verificaDocumentoIntegrazione: boolean = true;

    contaDocStepAttuale: number = 0;

    ref: DynamicDialogRef;
    fileCaricato: boolean;

    blockedDocument: boolean = false;
    tipoOfferta: string = ""
    is_dd: boolean = false;

    isDataLoaded: boolean = false


    @ViewChild("objFileUpload", { static: false }) objFileUpload: FileUpload;

    steps: { numero: number, descrizione: string, documenti: DocLavoro[], creazione: string, validato: boolean }[] = [
        { numero: 1, descrizione: "Istruttoria", documenti: [], creazione: "", validato: false },
        { numero: 2, descrizione: "Esecuzione Lavori", documenti: [], creazione: "", validato: false },
        { numero: 3, descrizione: "ENEA", documenti: [], creazione: "", validato: false },
        { numero: 4, descrizione: "Agenzia delle Entrate", documenti: [], creazione: "", validato: false },
        { numero: 5, descrizione: "Cessione del Credito", documenti: [], creazione: "", validato: false },
        { numero: 6, descrizione: "Attestazione Cessione", documenti: [], creazione: "", validato: false },
        { numero: 7, descrizione: "Attestazione Liquidazione", documenti: [], creazione: "", validato: false }
    ]

    catastale: string[] = [
        'A/1', 'A/2', 'A/3', 'A/4', 'A/5', 'A/6', 'A/7', 'A/8', 'A/9', 'A/10', 'A/11',
        'B/1', 'B/2', 'B/3', 'B/4', 'B/5', 'B/6', 'B/7', 'B/8',
        'C/1', 'C/2', 'C/3', 'C/4', 'C/5', 'C/6', 'C/7',
        'D/1', 'D/2', 'D/3', 'D/4', 'D/5', 'D/6', 'D/7', 'D/8', 'D/9', 'D/10'
    ];

    stepAttivo: number = 0;
    nessunaCriticitaDoc: boolean = false;

    environment = environment;
    baseUrl: string = environment.baseUrl;

    display = false;
    private ultimoStep = 7;

    controlloDoppione: boolean = false

    constructor(
        private messageSrv: MessageService,
        private uploadSrv: UploadService,
        private offertaSrv: LavoriService,
        private route: ActivatedRoute,
        private breadcrumbService: AppBreadcrumbService,
        private stepDocService: StepLavoriService,
        private abitazioneSrv: AbitazioniService,
        private listTypeSrv: ListTypeService,
        private utcDatePipe: UtcDatePipe,
        private chatPrevSrv: MessaggiPreventiviService,
        private dialogService: DialogService,
        private serramentistaSrv: SerramentistaService,) {
    }

    ngOnInit(): void {
        this.controlloDoppione = false;
        this.doc_26_caricato = false;
        this.doc_25_caricato = false;

        this.contaDocStepAttuale = 0;

        this.DisabilitaSte282 = true;
        this.DisabilitaSte283 = true;

        this.DisabilitaSte272 = true;
        this.DisabilitaSte273 = true;

        this.nessunaCriticitaDoc = false;
        this.creaFormAbitazione();
        this.modalAbitazione = false;
        this.ruoloUtente = sessionStorage.getItem('ruolo');
        this.idUtente = sessionStorage.getItem('id');
        this.breadcrumbService.setItems([
            { label: 'Step Lavori' },
        ]);
        this.refreshCategoriaCAtastale();
        this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');

        this.fileCaricato = false;
        this.isDataLoaded = false
    }

    creaFormAbitazione() {
        this.frmAbitazione = new FormGroup({
            ab_id: new FormControl(0),
            ab_cliente: new FormControl(0),
            ab_provincia: new FormControl(""),
            ab_citta: new FormControl(""),
            ab_indirizzo: new FormControl(""),
            ab_civico: new FormControl(""),
            ab_cap: new FormControl(""),
            ab_codice_comune: new FormControl("", Validators.required),
            ab_foglio: new FormControl("", Validators.required),
            ab_mappale: new FormControl("", Validators.required),
            ab_mq_immobile: new FormControl(0, Validators.required),
            ab_mq_infissi: new FormControl(0),
            ab_categoria_catastale: new FormControl("", Validators.required),
            ab_subalterno: new FormControl("", Validators.required),
            ab_titolarita: new FormControl(""),
            ab_cancellata: new FormControl(0),
        });
    }

    refreshOfferta(id: string): void {
        this.offertaSrv.getInterventoID(id).subscribe((res) => {
            this.offerta = res.data;
            this.is_dd = res.data?.lav_codice.includes("_DD_")
            this.tipoOfferta = res.data.lav_tipologia
            let prObj: Preventivo = JSON.parse(res.data.lav_preventivo.toString());
            // this.contributoSpettante = (prObj.contributoSpettante * -1) + prObj.importoNONDetraibileACaricoCliente;
            if (res.data.lav_codice.includes("_DD_")) {
                this.contributoSpettante = res.data.lav_importo;
            } else {
                this.contributoSpettante = prObj.ImportoLavoriCaricoCliente;
            }
            this.contributoSpettante = parseFloat(Number(this.contributoSpettante).toFixed(2));
            this.abitazione.ab_id = res.data.ab_id;
            this.abitazione.ab_cliente = res.data.ab_cliente;
            this.abitazione.ab_provincia = res.data.ab_provincia;
            this.abitazione.ab_citta = res.data.ab_citta;
            this.abitazione.ab_indirizzo = res.data.ab_indirizzo;
            this.abitazione.ab_civico = res.data.ab_civico;
            this.abitazione.ab_cap = res.data.ab_cap;
            this.abitazione.ab_codice_comune = res.data.ab_codice_comune;
            this.abitazione.ab_foglio = res.data.ab_foglio;
            this.abitazione.ab_mappale = res.data.ab_mappale;
            this.abitazione.ab_mq_immobile = res.data.ab_mq_immobile;
            this.abitazione.ab_mq_infissi = res.data.ab_mq_infissi;
            this.abitazione.ab_categoria_catastale = res.data.ab_categoria_catastale;
            this.abitazione.ab_subalterno = res.data.ab_subalterno;
            this.abitazione.ab_titolarita = res.data.ab_titolarita;
            this.abitazione.ab_cancellata = res.data.ab_cancellata;
            this.ultimoStep = (Number(res.data.lat_chiusura_pratica) == 0 || res.data.lat_chiusura_pratica == null) ? 7 : Number(res.data.lat_chiusura_pratica);
            this.refreshStep(this.route.snapshot.paramMap.get('id') ?? '0');
            this.refreshChatPreventivi(String(this.offerta.lat_prev_id));
            this.refreshChatAdminPreventivi(String(this.offerta.lat_prev_id));
            this.refreshSerramentista(String(this.offerta.id))
        });
    }

    refreshSerramentista(idCliente: string): void {
        this.serramentistaSrv.getSerramentista(idCliente).subscribe((res) => {
            this.serramentista = { ...res };
        });
    }


    refreshStep(idOfferta: string): void {
        this.isDataLoaded = false

        this.stepDocService.getStep(idOfferta).subscribe(async (res) => {
            const steps = res.data;
            this.steps = this.steps.filter((f) => f.numero <= this.ultimoStep);
            this.stepAttivo = steps.reduce((sp, sc) => Math.max(sc.step_numero, sp), 1);
            let documenti = await this.refreshDocumenti(idOfferta, this.stepAttivo);
            this.contaDocStepAttuale = 0

            for (const step of steps) {
                // VerificaCriticità Documenti ---------------------
                if ((this.stepAttivo == step.step_numero) && (this.stepAttivo == 1 || this.stepAttivo == 2)) {
                    let nrPuntiStep: number = this.stepAttivo == 2 ? (documenti[step.step_numero - 1].data.length) - 4 : documenti[step.step_numero - 1].data.length;
                    let criticita: boolean = false;

                    if (this.stepAttivo == 1) { this.doc_25_caricato = true }

                    documenti[step.step_numero - 1].data.forEach((x) => {
                        if (x.docl_nome != "") { this.contaDocStepAttuale += 1 };
                        if (x.docl_gestore == 2 || x.docl_gestore == 3) { criticita = true };
                        if (x.docl_step == 2 && x.docl_numero == 6 && x.docl_nome != '') { this.doc_26_caricato = true; }
                        if (x.docl_step == 2 && x.docl_numero == 4 && x.docl_nome != '') { this.doc_25_caricato = true; }
                    });
                    if (nrPuntiStep > this.contaDocStepAttuale) {
                        this.nessunaCriticitaDoc = false;
                    } else {
                        this.nessunaCriticitaDoc = !criticita
                    }
                }
                // FINE - VerificaCriticità Documenti ----------------------

                this.steps[step.step_numero - 1].documenti = documenti[step.step_numero - 1].data;
                this.steps[step.step_numero - 1].creazione = this.utcDatePipe.transform(step.step_data_aggiunta,
                    "yyyy-MM-dd",
                    "dd/MM/yyyy"
                );



                if (step.step_numero == 2) {
                    this.steps[step.step_numero - 1].documenti.map((d) => {
                        d.docl_data = this.utcDatePipe.transform(
                            d.docl_data,
                            "yyyy-MM-dd",
                            "dd/MM/yyyy"
                        );
                    })


                    if ((this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 71)?.docl_data) == null) {
                        this.dataStep271 = 0;
                    } else {
                        const d = new Date(this.utcDatePipe.transform(this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 71).docl_data));
                        this.dataStep271 = d.getFullYear();
                    }
                    if ((this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 72)?.docl_data) == null) {
                        this.dataStep272 = 0;
                    } else {
                        const d = new Date(this.utcDatePipe.transform(this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 72).docl_data));
                        this.dataStep272 = d.getFullYear();
                    }
                    if ((this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 73)?.docl_data) == null) {
                        this.dataStep273 = 0;
                    } else {
                        const d = new Date(this.utcDatePipe.transform(this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 73).docl_data));
                        this.dataStep273 = d.getFullYear();
                    }

                    this.importoStep271 = this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 71)?.docl_importo;
                    this.importoStep272 = this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 72)?.docl_importo;
                    this.importoStep273 = this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 73)?.docl_importo;

                    if ((this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 81).docl_data) == null) {
                        this.dataStep281 = 0;
                    } else {
                        const d = new Date(this.utcDatePipe.transform(this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 81).docl_data));
                        this.dataStep281 = d.getFullYear();
                    }
                    if ((this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 82).docl_data) == null) {
                        this.dataStep282 = 0;
                    } else {
                        const d = new Date(this.utcDatePipe.transform(this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 82).docl_data));
                        this.dataStep282 = d.getFullYear();
                    }
                    if ((this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 83).docl_data) == null) {
                        this.dataStep283 = 0;
                    } else {
                        const d = new Date(this.utcDatePipe.transform(this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 83).docl_data));
                        this.dataStep283 = d.getFullYear();
                    }

                    this.importoStep281 = this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 81).docl_importo;
                    this.importoStep282 = this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 82).docl_importo;
                    this.importoStep283 = this.steps[step.step_numero - 1].documenti.find((f) => f.docl_step === 2 && f.docl_numero === 83).docl_importo;

                    this.DisabilitaDocumentiStep2();

                }



                if (step.step_numero == 2) {
                    this.verificaDocumentoIntegrazione = true;
                    documenti[step.step_numero - 1].data.forEach((x) => {

                        if (x.docl_numero <= 6) {
                            if (x.docl_nome == '') {
                                this.verificaDocumentoIntegrazione = false;
                            }
                        }

                        if (this.verificaDocumentoIntegrazione) {
                            if (x.docl_numero == 71 || x.docl_numero == 72 || x.docl_numero == 73) {
                                let sommaFat: number = 0;
                                sommaFat = Number(this.importoStep271) + Number(this.importoStep272) + Number(this.importoStep273);
                                if ((sommaFat <= (this.contributoSpettante + 3)) && (sommaFat >= (this.contributoSpettante - 3))) {
                                    this.verificaDocumentoIntegrazione = true;
                                } else { this.verificaDocumentoIntegrazione = false; }
                            }
                        }

                        if (this.verificaDocumentoIntegrazione) {
                            if (x.docl_numero == 81 || x.docl_numero == 82 || x.docl_numero == 83) {
                                let sommaBonifici: number = 0;
                                sommaBonifici = Number(this.importoStep281) + Number(this.importoStep282) + Number(this.importoStep283);
                                if ((sommaBonifici <= (this.contributoSpettante + 3)) && (sommaBonifici >= (this.contributoSpettante - 3))) {
                                    this.verificaDocumentoIntegrazione = true;
                                } else { this.verificaDocumentoIntegrazione = false; }
                            }
                        }
                    })
                }


                if (step.step_numero == 4) {

                    let importo1: number = this.importoStep281;
                    let importo2: number = 0;
                    this.doc_41_anno = this.dataStep281;

                    if (this.dataStep282 == this.dataStep281 && this.dataStep282 != 0) {
                        importo1 = Number(importo1) + Number(this.importoStep282);
                    } else {
                        importo2 = Number(importo2) + Number(this.importoStep282);
                        this.doc_43_anno = this.dataStep282
                    }

                    if (this.dataStep283 == this.dataStep281 && this.dataStep283 != 0) {
                        importo1 = Number(importo1) + Number(this.importoStep283);
                    } else {
                        importo2 = Number(importo2) + Number(this.importoStep283);
                        this.doc_43_anno = this.dataStep283
                    }
                    this.doc_41_importo = importo1;
                    this.doc_43_importo = importo2;
                }
                this.steps[step.step_numero - 1].validato = this.steps[step.step_numero - 1].documenti.every((d) =>
                    d.docl_gestore == 4
                    || d.docl_numero == 7
                    || d.docl_numero == 8
                    || d.docl_numero == 14
                    || (_.isEmpty(d.docl_nome) && d.docl_importo == 0 && d.docl_step == 2 && d.docl_numero == 72)
                    || (_.isEmpty(d.docl_nome) && d.docl_importo == 0 && d.docl_step == 2 && d.docl_numero == 73)
                    || (_.isEmpty(d.docl_nome) && d.docl_importo == 0 && d.docl_step == 2 && d.docl_numero == 82)
                    || (_.isEmpty(d.docl_nome) && d.docl_importo == 0 && d.docl_step == 2 && d.docl_numero == 83)
                    || (this.doc_43_importo == 0 && d.docl_step == 4 && d.docl_numero == 3)
                    || (this.doc_43_importo == 0 && d.docl_step == 4 && d.docl_numero == 4)
                );

            }
        });
        this.isDataLoaded = true

    }

    DisabilitaDocumentiStep2() {
        if (Number(this.importoStep281) === 0) {
            this.DisabilitaSte282 = true;
            this.DisabilitaSte283 = true;
        } else {
            if ((Number(this.importoStep282) === 0)) {
                if ((Number(this.contributoSpettante) - Number(this.importoStep281)) > 3) {
                    this.DisabilitaSte282 = false;
                    this.DisabilitaSte283 = true;
                } else {
                    this.DisabilitaSte282 = true;
                    this.DisabilitaSte283 = true;
                }
            }
            if ((Number(this.importoStep282) > 0)) {
                if ((Number(this.contributoSpettante) - (Number(this.importoStep281) + Number(this.importoStep282))) > 3) {
                    this.DisabilitaSte282 = false;
                    this.DisabilitaSte283 = false;
                } else {
                    this.DisabilitaSte282 = false;
                    this.DisabilitaSte283 = true;
                }
            }

        }

        if (Number(this.importoStep271) === 0) {
            this.DisabilitaSte272 = true;
            this.DisabilitaSte273 = true;
        } else {
            if ((Number(this.importoStep272) === 0)) {
                if ((Number(this.contributoSpettante) - Number(this.importoStep271)) > 3) {
                    this.DisabilitaSte272 = false;
                    this.DisabilitaSte273 = true;
                } else {
                    this.DisabilitaSte272 = true;
                    this.DisabilitaSte273 = true;
                }
            }
            if ((Number(this.importoStep272) > 0)) {
                if ((Number(this.contributoSpettante) - (Number(this.importoStep271) + Number(this.importoStep272))) > 3) {
                    this.DisabilitaSte272 = false;
                    this.DisabilitaSte273 = false;
                } else {
                    this.DisabilitaSte272 = false;
                    this.DisabilitaSte273 = true;
                }
            }

        }

    }

    refreshDocumenti(idOfferta: string, step: number) {
        let requests = Array.from({ length: step }, (_, i) => this.stepDocService.getDocumentiStep(idOfferta, i + 1));
        return forkJoin(requests).toPromise();
    }

    refreshCategoriaCAtastale(): void {
        this.listTypeSrv.getCatastale().subscribe((res) => {
            this.categoriaCatastale = [...res.data];
        });
    }

    refreshChatPreventivi(idOfferta: string): void {
        this.chatPrevSrv.getMessaggiByPreventivo(idOfferta).subscribe((res) => {

            const convertToDate = (dataString: string): Date => {
                return new Date(dataString);
            };

            const sortedData = res.data.sort((a: any, b: any) => {
                return convertToDate(b.msga_data).getTime() - convertToDate(a.msga_data).getTime();
            });
            this.chatPreventivi = [...sortedData];
        });
    }

    refreshChatAdminPreventivi(idOfferta: string,): void {
        this.chatPrevSrv.getMessaggiAdminByPrev(idOfferta).subscribe((res) => {
            const convertToDate = (dataString: string): Date => {
                return new Date(dataString);
            };

            const sortedData = res.data.sort((a: any, b: any) => {
                return convertToDate(b.msga_data).getTime() - convertToDate(a.msga_data).getTime();
            });
            this.chatAdminPreventivi = [...sortedData];
        });
    }


    submitAbitazione(): void {
        this.abitazioneSrv
            .addAbitazioni(this.frmAbitazione.value)
            .subscribe((res) => {
                this.messageSrv.add({
                    key: "tst",
                    severity: res.res === "ok" ? "success" : "danger",
                    summary: `Abitazione`,
                    detail: res.message,
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                this.messageSrv.add({
                    key: "tst",
                    severity: "info",
                    summary: `Upload File`,
                    detail: 'Ora è possibile inserire il File',
                    life: 8000 // Tempo in millisecondi (10 secondi),
                });
                this.creaFormAbitazione();
                this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');
                this.modalAbitazione = false;
            });
    }

    verificaDatiCatastali(docStep: number, numDoc: number): boolean {
        if (docStep === 1 && numDoc === 3) {
            if (this.offerta.ab_codice_comune === "" ||
                this.offerta.ab_categoria_catastale === null ||
                this.offerta.ab_foglio === "" ||
                this.offerta.ab_mappale === "" ||
                this.offerta.ab_subalterno === ""
            ) {
                this.frmAbitazione.patchValue(this.abitazione)
                this.modalAbitazione = true;
                this.messageSrv.add({
                    key: 'tst',
                    severity: 'warn',
                    summary: `ATTENZIONE!`,
                    detail: "Inserire i dati catastali per proseguire",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }

    onUpload(event, docStep: number, numDoc: number, docTipo: string, stepAttivo: number) {
        this.upFile = null;
        if (this.verificaDatiCatastali(docStep, numDoc) === true) {
            for (let file of event.files) {
                this.upFile = file;
            }
            this.uploadFileToActivity(docStep, numDoc, docTipo, stepAttivo);
        } else {
            this.objFileUpload.ngOnDestroy();
        }
    }

    onUploadAdmin(event, codice: string, step: string, numero: string, tipo: string) {
        this.upFile = null;
        {
            for (let file of event.files) {
                this.upFile = file;
            }
            this.uploadFileToActivityAdmin(codice, `${step}${numero}`, tipo);
            step
        }
    }

    onUploadStep2(event) {
        this.upFile = null;
        for (let file of event.files) {
            this.upFile = file;
        }

        this.fileCaricato = false;
        this.uploadFileToActivityStep2();
    }

    uploadFileToActivityAdmin(codice: string, step: string, tipo: string) {
        this.uploadSrv.uploadAdmin(this.upFile, codice, step, tipo).subscribe((res) => {
            this.messageSrv.add({
                key: 'tst',
                severity: 'info',
                summary: `File Caricato`,
                detail: res.nome_file,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.fileCaricato = true;
            this.objFileUpload.ngOnDestroy();
            this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');
        }, error => {
            this.messageSrv.add({
                key: 'tst',
                severity: 'error',
                summary: `File Caricato`,
                detail: 'ERRORE NEL CARICAMENTO ATTENDERE E RIPROVARE',
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
        });
    }

    uploadFileToActivityStep2() {
        this.uploadSrv.uploadStep2(this.upFile, String(this.offerta.lav_id)).subscribe((res) => {
            this.messageSrv.add({
                key: 'tst',
                severity: 'info',
                summary: `File Caricato`,
                detail: res.nome_file,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.fileCaricato = true;
            this.objFileUpload.ngOnDestroy();
            this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChat(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChatAdmin(this.route.snapshot.paramMap.get('id') ?? '0');
        }, error => {
            this.messageSrv.add({
                key: 'tst',
                severity: 'error',
                summary: `File Caricato`,
                detail: 'ERRORE NEL CARICAMENTO ATTENDERE E RIPROVARE',
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
        });
    }

    uploadFileToActivity(docStep: number, numDoc: number, docTipo: string, stepAttivo: number) {
        this.uploadSrv.upload(this.upFile, this.offerta?.lav_codice, String(docStep), String(numDoc)).subscribe((res) => {
            this.messageSrv.add({
                key: 'tst',
                severity: 'info',
                summary: `File Caricato`,
                detail: res.nome_file,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });

            let Doc: DocLavoro = {};
            this.stepDocService.getDocumentiStepRiga(this.offerta.lav_id, docStep, numDoc).subscribe((resDoc) => {
                this.documentiStepRiga = resDoc.data;
                if (!_.isEmpty(this.documentiStepRiga)) {

                    Doc.docl_id = this.documentiStepRiga[0].docl_id;
                    Doc.docl_lavoro = this.documentiStepRiga[0].docl_lavoro;
                    Doc.docl_step = this.documentiStepRiga[0].docl_step;
                    Doc.docl_numero = this.documentiStepRiga[0].docl_numero;
                    Doc.docl_nome = res.nome_file;
                    Doc.docl_op = "DOC";
                    Doc.docl_tipo = this.documentiStepRiga[0].docl_tipo;
                    Doc.docl_importo = this.documentiStepRiga[0].docl_importo;
                    Doc.docl_data = this.documentiStepRiga[0].docl_data;
                    Doc.docl_note = this.documentiStepRiga[0].docl_note;
                    Doc.docl_responsabile = this.documentiStepRiga[0].docl_responsabile;
                    if (docStep != stepAttivo) {
                        Doc.docl_gestore = this.documentiStepRiga[0].docl_gestore;;
                        Doc.docl_tutor = this.documentiStepRiga[0].docl_tutor;;
                    } else {
                        Doc.docl_gestore = 0;
                        Doc.docl_tutor = 0;
                    }

                    this.stepDocService.addDettaglioUpload(Doc).subscribe((res) => {
                        this.refreshStep(this.offerta.lav_id.toString())
                    });
                } else {
                    this.stepDocService.addDettaglioUpload(Doc).subscribe((res) => {
                        this.refreshStep(this.offerta.lav_id.toString())
                    });
                }
            })
        }, error => {
            this.messageSrv.add({
                key: 'tst',
                severity: 'error',
                summary: `File Caricato`,
                detail: 'ERRORE NEL CARICAMENTO ATTENDERE E RIPROVARE',
                life: 8000 // Tempo in millisecondi (10 secondi)
                // detail: 'ERRORE: File NON Caricato',
            });
        });
    }

    ConvalidaApriStep(step: number): void {
        if (step == 2) {
            const sommaBonifici = Number(this.importoStep282) + Number(this.importoStep283) + Number(this.importoStep281);
            if (sommaBonifici > (this.contributoSpettante + 3) || sommaBonifici < (this.contributoSpettante - 3)) {
                this.messageSrv.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `Errore`,
                    detail: "Errore – la somma degli importi dei Contabili dei bonifici parlanti differiscono dall'importo lavori a carico del cliente",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }
            const sommaFat = Number(this.importoStep272) + Number(this.importoStep273) + Number(this.importoStep271);
            if (sommaFat > (this.contributoSpettante + 3) || sommaFat < (this.contributoSpettante - 3)) {
                this.messageSrv.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `Errore`,
                    detail: "Errore – la somma degli importi della Fattura differiscono dall'importo lavori a carico del cliente",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }

        }


        this.blockedDocument = true;
        this.controlloDoppione = true
        this.step.step_lavoro = this.offerta.lav_id;
        this.step.step_numero = step;
        this.step.step_validato = 1;
        this.stepDocService.addStep(this.step).subscribe((res) => {
            if ((step + 1) <= this.ultimoStep) {
                this.step.step_numero = step + 1;
                this.step.step_validato = 0;
                this.stepDocService.addStep(this.step).subscribe((res) => {
                    this.refreshStep(this.offerta.lav_id.toString())
                    this.messageSrv.add({
                        key: 'tst',
                        severity: 'info',
                        summary: `Convalida`,
                        detail: res.message,
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    this.blockedDocument = false;
                    this.controlloDoppione = false;
                });
            } else if ((step + 1) == this.ultimoStep + 1) {
                this.messageSrv.add({
                    key: 'tst',
                    severity: 'info',
                    summary: `Convalida`,
                    detail: res.message,
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                this.blockedDocument = false;
                this.controlloDoppione = false;
            }
        });
    }

    CambioStatoObb(stato: number, etichetta: string, docStep: number, numDoc: number): void {
        let doc: DocLavoro = {};
        this.stepDocService.getDocumentiStepRiga(this.offerta.lav_id, docStep, numDoc).subscribe((resDoc) => {
            this.documentiStepRiga = resDoc.data;
            if (!_.isEmpty(this.documentiStepRiga)) {
                doc.docl_id = this.documentiStepRiga[0].docl_id;
                doc.docl_lavoro = this.documentiStepRiga[0].docl_lavoro;
                doc.docl_step = this.documentiStepRiga[0].docl_step;
                doc.docl_numero = this.documentiStepRiga[0].docl_numero;
                doc.docl_nome = this.documentiStepRiga[0].docl_nome;
                doc.docl_tipo = this.documentiStepRiga[0].docl_tipo;
                doc.docl_importo = this.documentiStepRiga[0].docl_importo;
                doc.docl_data = this.documentiStepRiga[0].docl_data;
                doc.docl_note = this.documentiStepRiga[0].docl_note;
                doc.docl_responsabile = this.documentiStepRiga[0].docl_responsabile;
                doc.docl_gestore = stato;
                doc.docl_op = "VAL";
                doc.docl_tutor = this.documentiStepRiga[0].docl_tutor;
                this.stepDocService.addDettaglioUpload(doc).subscribe((res) => {
                    this.messageSrv.add({
                        key: 'tst',
                        severity: 'info',
                        summary: `Cambio Stato`,
                        detail: "Modifica Avvenuta con successo",
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    this.refreshStep(this.offerta.lav_id.toString())
                })
            } else {
                this.refreshStep(this.offerta.lav_id.toString())
            }
        });
        this.display = false;
    }

    CambioStatoFac(stato: number, etichetta: string, docStep: number, numDoc: number): void {
        let doc: DocLavoro = {};
        this.stepDocService.getDocumentiStepRiga(this.offerta.lav_id, docStep, numDoc).subscribe((resDoc) => {
            this.documentiStepRiga = resDoc.data;
            if (!_.isEmpty(this.documentiStepRiga)) {
                doc.docl_id = this.documentiStepRiga[0].docl_id;
                doc.docl_lavoro = this.documentiStepRiga[0].docl_lavoro;
                doc.docl_step = this.documentiStepRiga[0].docl_step;
                doc.docl_numero = this.documentiStepRiga[0].docl_numero;
                doc.docl_nome = this.documentiStepRiga[0].docl_nome;
                doc.docl_tipo = this.documentiStepRiga[0].docl_tipo;
                doc.docl_responsabile = this.documentiStepRiga[0].docl_responsabile;
                doc.docl_importo = this.documentiStepRiga[0].docl_importo;
                doc.docl_data = this.documentiStepRiga[0].docl_data;
                doc.docl_note = this.documentiStepRiga[0].docl_note;
                doc.docl_gestore = this.documentiStepRiga[0].docl_gestore;
                doc.docl_tutor = stato;
                doc.docl_op = "VAL";
                this.stepDocService.addDettaglioUpload(doc).subscribe((res) => {
                    this.messageSrv.add({
                        key: 'tst',
                        severity: 'info',
                        summary: `Cambio Stato`,
                        detail: "Modifica Avvenuta con successo",
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    this.refreshStep(this.offerta.lav_id.toString())
                })
            } else {
                this.refreshStep(this.offerta.lav_id.toString())
            }
        });
        this.display = false;
    }

    submitImporto(importo: number, data: string, docStep: number, numDoc: number, controllo: boolean): void {
        if ((importo === null || !data) && controllo) {
            this.messageSrv.add({
                key: 'tst',
                severity: 'error',
                summary: `Errore`,
                detail: "Inserire un importo o data valida",
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            return;
        }

        if (this.VerificaAnnoStep2(data, numDoc) === false && controllo === true) {
            this.messageSrv.add({
                key: 'tst',
                severity: 'error',
                summary: `Errore`,
                detail: "Errore – la data inserita non è compresa in un intervallo di 2 anni",
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            return;
        }

        let sommaBonifici: number = 0;
        if ((numDoc === 81) && controllo) {
            sommaBonifici = Number(this.importoStep282) + Number(this.importoStep283) + Number(importo);
            if ((sommaBonifici > (this.contributoSpettante + 3)) && controllo) {
                this.messageSrv.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `Errore`,
                    detail: "Errore – la somma dei pagamenti differisce dall'importo lavori a carico del cliente",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }
        }
        if ((numDoc === 82) && controllo) {
            sommaBonifici = Number(this.importoStep281) + Number(this.importoStep283) + Number(importo);
            if ((sommaBonifici > (this.contributoSpettante + 3)) && controllo) {
                this.messageSrv.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `Errore`,
                    detail: "Errore – la somma dei pagamenti differisce dall'importo lavori a carico del cliente",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }
        }
        if ((numDoc === 83) && controllo) {
            sommaBonifici = Number(this.importoStep281) + Number(this.importoStep282) + Number(importo);
            const diff: number = this.contributoSpettante - sommaBonifici;
            if ((diff < -3 || diff > 3) && controllo) {
                this.messageSrv.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `Errore`,
                    detail: "Errore – la somma dei pagamenti differisce dall'importo lavori a carico del cliente",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }
        }

        if ((numDoc === 71) && controllo) {
            sommaBonifici = Number(this.importoStep272) + Number(this.importoStep273) + Number(importo);
            if ((sommaBonifici > (this.contributoSpettante + 3)) && controllo) {
                this.messageSrv.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `Errore`,
                    detail: "Errore – la somma dei pagamenti differisce dall'importo lavori a carico del cliente",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }
        }
        if ((numDoc === 72) && controllo) {
            sommaBonifici = Number(this.importoStep271) + Number(this.importoStep273) + Number(importo);
            if ((sommaBonifici > (this.contributoSpettante + 3)) && controllo) {
                this.messageSrv.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `Errore`,
                    detail: "Errore – la somma dei pagamenti differisce dall'importo lavori a carico del cliente",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }
        }
        if ((numDoc === 73) && controllo) {
            sommaBonifici = Number(this.importoStep271) + Number(this.importoStep272) + Number(importo);
            const diff: number = this.contributoSpettante - sommaBonifici;
            if ((diff < -3 || diff > 3) && controllo) {
                this.messageSrv.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `Errore`,
                    detail: "Errore – la somma dei pagamenti differisce dall'importo lavori a carico del cliente",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }
        }

        this.stepDocService.getDocumentiStepRiga(this.offerta.lav_id, docStep, numDoc).subscribe((resDoc) => {
            let doc = resDoc.data[0];
            if (!_.isEmpty(doc)) {
                doc.docl_importo = importo
                doc.docl_data = this.utcDatePipe.transform(data, "dd/MM/yyyy", "yyyy-MM-dd");
                doc.docl_op = "DOC"
                if (controllo === false) {
                    doc.docl_nome = "";
                    doc.docl_gestore = 1;
                };
                this.stepDocService.addDettaglioUpload(doc).subscribe((res) => {
                    this.messageSrv.add({
                        key: 'tst',
                        severity: 'info',
                        summary: `Data Valuta / Importo`,
                        detail: "Modifica Avvenuta con successo",
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    this.refreshStep(this.offerta.lav_id.toString())
                })
            } else {
                this.refreshStep(this.offerta.lav_id.toString())
            }
        });
        this.display = false;
    }

    VerificaAnnoStep2(data: string, numDoc: number): boolean {
        const d1 = new Date(this.utcDatePipe.transform(data));
        const anno: number = d1.getFullYear();
        const datCorrente = new Date();
        const annoCorrente = datCorrente.getFullYear();
        if (anno > annoCorrente) {
            return false
        }
        ;
        //  DOCUMENTO STEP 21 ----------------------------------------
        if (numDoc === 81) {
            if (this.dataStep281 === 0 && this.dataStep282 === 0 && this.dataStep283 === 0) {
                return true
            } else if (this.dataStep281 != 0 && this.dataStep282 === 0 && this.dataStep283 === 0) {
                return true
            } else if (this.dataStep281 != 0 && this.dataStep282 != 0 && this.dataStep283 === 0) {
                if ((this.dataStep282 - anno) >= -1 && (this.dataStep282 - anno) <= 1) {
                    return true
                } else {
                    return false
                }
            } else if (this.dataStep281 != 0 && this.dataStep282 != 0 && this.dataStep283 != 0) {
                if (((this.dataStep282 + this.dataStep283) - (anno * 2)) <= 2 && ((this.dataStep282 + this.dataStep283) - (anno * 2)) >= -2) {
                    return true
                } else {
                    return false
                }
            }
        }
        //  DOCUMENTO STEP 22 ----------------------------------------
        if (numDoc === 82) {
            if (this.dataStep281 != 0 && this.dataStep282 === 0 && this.dataStep283 === 0) {
                if ((this.dataStep281 - anno) >= -1 && (this.dataStep281 - anno) <= 1) {
                    return true
                } else {
                    return false
                }
            } else if (this.dataStep281 != 0 && this.dataStep282 != 0 && this.dataStep283 === 0) {
                if ((this.dataStep281 - anno) >= -1 && (this.dataStep281 - anno) <= 1) {
                    return true
                } else {
                    return false
                }
            } else if (this.dataStep281 != 0 && this.dataStep282 != 0 && this.dataStep283 != 0) {
                if (((this.dataStep281 + this.dataStep283) - (anno * 2)) <= 2 && ((this.dataStep281 + this.dataStep283) - (anno * 2)) >= -2) {
                    return true
                } else {
                    return false
                }
            }
        }
        //  DOCUMENTO STEP 23 ----------------------------------------
        if (numDoc === 83) {
            if (this.dataStep281 != 0 && this.dataStep282 != 0) {
                if (((this.dataStep281 + this.dataStep282) - (anno * 2)) <= 2) {
                    return true
                } else {
                    return false
                }
            }
        }




        //  DOCUMENTO STEP 21 ----------------------------------------
        if (numDoc === 71) {
            if (this.dataStep271 === 0 && this.dataStep272 === 0 && this.dataStep273 === 0) {
                return true
            } else if (this.dataStep271 != 0 && this.dataStep272 === 0 && this.dataStep273 === 0) {
                return true
            } else if (this.dataStep271 != 0 && this.dataStep272 != 0 && this.dataStep273 === 0) {
                if ((this.dataStep272 - anno) >= -1 && (this.dataStep272 - anno) <= 1) {
                    return true
                } else {
                    return false
                }
            } else if (this.dataStep271 != 0 && this.dataStep272 != 0 && this.dataStep273 != 0) {
                if (((this.dataStep272 + this.dataStep273) - (anno * 2)) <= 2 && ((this.dataStep272 + this.dataStep273) - (anno * 2)) >= -2) {
                    return true
                } else {
                    return false
                }
            }
        }
        //  DOCUMENTO STEP 12 ----------------------------------------
        if (numDoc === 72) {
            if (this.dataStep271 != 0 && this.dataStep272 === 0 && this.dataStep273 === 0) {
                if ((this.dataStep271 - anno) >= -1 && (this.dataStep271 - anno) <= 1) {
                    return true
                } else {
                    return false
                }
            } else if (this.dataStep271 != 0 && this.dataStep272 != 0 && this.dataStep273 === 0) {
                if ((this.dataStep271 - anno) >= -1 && (this.dataStep271 - anno) <= 1) {
                    return true
                } else {
                    return false
                }
            } else if (this.dataStep271 != 0 && this.dataStep272 != 0 && this.dataStep273 != 0) {
                if (((this.dataStep271 + this.dataStep273) - (anno * 2)) <= 2 && ((this.dataStep271 + this.dataStep273) - (anno * 2)) >= -2) {
                    return true
                } else {
                    return false
                }
            }
        }
        //  DOCUMENTO STEP 73 ----------------------------------------
        if (numDoc === 73) {
            if (this.dataStep271 != 0 && this.dataStep272 != 0) {
                if (((this.dataStep271 + this.dataStep272) - (anno * 2)) <= 2) {
                    return true
                } else {
                    return false
                }
            }
        }


    }

    OpenModalStato(GestTudor: string, idStep: number, idDoc: number): void {
        this.display = true;
        this.cambiostatoStep = idStep;
        this.cambiostatoDoc = idDoc;
        this.cambiostatoGestoreTudor = GestTudor;
    }

    DocNONVisionato(): void {
        if (this.cambiostatoGestoreTudor === 'G') {
            this.CambioStatoObb(1, "Documento non ancora visionato", this.cambiostatoStep, this.cambiostatoDoc);
        } else {
            this.CambioStatoFac(1, "Documento non ancora visionato", this.cambiostatoStep, this.cambiostatoDoc);
        }

    }

    DocCriticita(): void {
        if (this.cambiostatoGestoreTudor === 'G') {
            this.CambioStatoObb(2, "Documento da rivedere/sostituire: criticità seria", this.cambiostatoStep, this.cambiostatoDoc);
        } else {
            this.CambioStatoFac(2, "Documento da rivedere/sostituire: criticità seria", this.cambiostatoStep, this.cambiostatoDoc);

        }
    }

    DocNONCriticita(): void {
        if (this.cambiostatoGestoreTudor === 'G') {
            this.CambioStatoObb(3, "Documento da rivedere/sostituire: criticità non seria", this.cambiostatoStep, this.cambiostatoDoc);
        } else {
            this.CambioStatoFac(3, "Documento da rivedere/sostituire: criticità non seria", this.cambiostatoStep, this.cambiostatoDoc);
        }
    }

    DocValidato(): void {
        if (this.cambiostatoGestoreTudor === 'G') {
            this.CambioStatoObb(4, "Documento validato", this.cambiostatoStep, this.cambiostatoDoc);
        } else {
            this.CambioStatoFac(4, "Documento validato", this.cambiostatoStep, this.cambiostatoDoc);
        }
    }

    IconaCheck(stato: number, num: number, step: number): string {
        if (stato === 1 || stato === 0) {
            return "pi pi-question-circle";
        } else if (stato === 2) {
            return "pi pi-info-circle";
        } else if (stato === 3) {
            return "pi pi-times-circle";
        } else {
            return "pi pi-check-circle";
        }
    }

    IconaCheck2(stato: number, num: number, step: number): string {

        return "pi pi-question-circle";

    }

    ColorButton(stato: number): string {
        if (stato == 1 || stato == 0) {
            return "p-button-secondary";
        } else if (stato == 2) {
            return "p-button-danger";
        } else if (stato == 3) {
            return "p-button-warning";
        } else {
            return "p-button-success";
        }
    }

    cancelModale() {
        this.modalAbitazione = false;
    }

    convertiDateSubmit = (data: DocLavoro) => {
        data.docl_data = this.utcDatePipe.transform(
            data.docl_data,
            "yyyy-MM-dd",
            "dd/MM/yyyy"
        );
    };

    controlloVisibile(doc: DocLavoro): boolean {

        if (Number(this.doc_43_importo) == 0 && doc.docl_step === 4 && doc.docl_numero === 3) {
            return false;
        }
        if (Number(this.doc_43_importo) == 0 && doc.docl_step === 4 && doc.docl_numero === 4) {
            return false;
        }
        if ((doc.docl_step === 6 || doc.docl_step === 5 || doc.docl_step === 7) && (this.ruoloUtente === 'SE')) {
            return false;
        }
        if (doc.docl_step === 2 && doc.docl_numero === 7) {
            return false;
        }
        if (doc.docl_step === 2 && doc.docl_numero === 8) {
            return false;
        }
        if (doc.docl_step === 2 && doc.docl_numero === 4 && doc.docl_nome != '') {
            this.doc_26_caricato = true
        }
        if (sessionStorage.getItem("ruolo") === "AD") {
            return true;
        } else {
            if (sessionStorage.getItem("ruolo") === "SE" && doc.docl_responsabile === "ORC") {
                return false;
            } else {
                return true;
            }
        }

    }

    DisabilitaAbilitaCheck(doc: DocLavoro, chk: string): boolean {
        //true -> disabilita
        //false -> abilita
        if (this.stepAttivo == 6 && doc.docl_step == 6 && doc.docl_numero == 1 && chk == 'G' && this.ruoloUtente != 'SE') {
            return false
        }
        if (this.stepAttivo == 5 && doc.docl_step == 5 && doc.docl_numero == 1 && doc.la_id != null && this.ruoloUtente != 'SE') {
            return false;
        }

        if (!doc.docl_nome || this.ruoloUtente === 'SE') {
            return true;
        }

        if (this.stepAttivo != doc.docl_step) {
            return true;
        }

    }

    disabilitaContributo(doc: DocLavoro): boolean {
        if (doc.docl_numero === 81) {
            return false;
        }
        if (doc.docl_numero === 82) {
            return this.DisabilitaSte282;
        }
        if (doc.docl_numero === 83) {
            return this.DisabilitaSte283;
        }

        if (doc.docl_numero === 71) {
            return false;
        }
        if (doc.docl_numero === 72) {
            return this.DisabilitaSte272;
        }
        if (doc.docl_numero === 73) {
            return this.DisabilitaSte273;
        }

    }

    ControlloAdminGestore(step: number): boolean {
        let ritorno: boolean = false;
        if (this.ruoloUtente == 'AD') {
            ritorno = false;
        } else if (step === 4) {
            ritorno = true;
        }
        return ritorno;
    }

    NumberStep(step: string, numero: number): string {
        if (numero === 71 || numero === 72 || numero === 73 || numero === 81 || numero === 82 || numero === 83 || numero === 26) {
            return step + '.' + numero.toString().substring(0, 1) + '.' + numero.toString().substring(1)
        } else {
            return step + '.' + numero
        }
    }


    // CHAT --------------------------
    sendMessagePreventivo(): void {
        let msg: Messaggi = {}
        msg.msg_lavoro = Number(this.offerta.lat_prev_id);
        msg.msg_serramentista = Number(this.idUtente);
        if (this.ruoloUtente === 'AD') {
            msg.msg_is_admin = 1;
        } else {
            msg.msg_is_admin = 0;
        }
        msg.msg_testo = this.messageChatPreventivo;
        msg.msg_admin_letto = 0;
        this.chatPrevSrv.addMessaggio(msg).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: res.message,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshChatPreventivi(String(this.offerta.lat_prev_id))
        });
    }

    sendMessageAdminPreventivo(): void {
        let msg: MessaggiAdmin = {}
        msg.msga_lavoro = Number(String(this.offerta.lat_prev_id));
        msg.msga_admin = sessionStorage.getItem("name");
        msg.msga_testo = this.messageChatAdminPreventivo;
        this.chatPrevSrv.addMessaggioAdminPrev(msg).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: res.message,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshChatAdminPreventivi(String(this.offerta.lat_prev_id))
        });
    }

    VerificaMessaggioNonLettoPreventivi(isadmin: number, letto: number, admin_letto: number): string {
        if (this.ruoloUtente === 'SE') {
            if (isadmin != 0 && letto == 0) {
                return 'font-weight: bold; font-style: italic; color: #00ff00;'
            } else {
                return ''
            }
        }
        if (this.ruoloUtente === 'AD') {
            if (isadmin == 0 && admin_letto == 0) {
                return 'font-weight: bold; font-style: italic; color: #00ff00;'
            } else {
                return ''
            }
        }
    }

    VerificaMessaggioAdminSerramentistaPreventivi(idserr: number): string {
        if (this.ruoloUtente == "SE") {
            if (idserr == Number(this.idUtente)) {
                return 'message-own'
            } else {
                return 'message-from'
            }
        } else {
            if (idserr == this.offerta.lat_prev_id) {
                return 'message-own'
            } else {
                return 'message-from'
            }
        }

    }


    MessaggiLettiPreventivi(): void {
        let msg: Messaggi = {}
        msg.msg_lavoro = Number(String(this.offerta.lat_prev_id));
        msg.msg_admin_letto = Number(this.idUtente);
        msg.msg_letto = 0;
        msg.msg_ruolo = this.ruoloUtente;
        this.chatPrevSrv.updMessaggiLetti(msg).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: res.message,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshChatPreventivi(String(this.offerta.lat_prev_id))
        });
    }


    MessaggiLettiSerramentistaPreventivi(): void {
        let msg: Messaggi = {}
        msg.msg_lavoro = Number(String(this.offerta.lat_prev_id));
        msg.msg_admin_letto = 0;
        msg.msg_letto = 1;
        msg.msg_ruolo = this.ruoloUtente;
        this.chatPrevSrv.updMessaggiLetti(msg).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: res.message,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshChatPreventivi(String(this.offerta.lat_prev_id))
        });
    }


    delMessaggioPreventivo(idMessage: number): void {
        let msg: Messaggi = {}
        msg.msg_id = idMessage;
        msg.msg_admin_letto = Number(this.idUtente);
        msg.msg_canc = 1;
        this.chatPrevSrv.cancMessaggio(msg).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: res.message,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshChatPreventivi(String(this.offerta.lat_prev_id))
        });
    }

    MessaggioNONLEttoPreventivo(idMessage: number): void {
        let msg: Messaggi = {}
        msg.msg_id = idMessage;
        msg.msg_admin_letto = 0;
        msg.msg_letto = 0;
        msg.msg_ruolo = 'AD'
        this.chatPrevSrv.updMessaggioNONLetto(msg).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: res.message,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshChatPreventivi(String(this.offerta.lat_prev_id))
        });
    }


    openModalDownStep5(id, nome): void {
        this.ref = this.dialogService.open(HtmlPreviewStep5Component, {
            width: '70%',
            contentStyle: { "overflow": "auto" },
            baseZIndex: 10000,
            data: { id: id, nome: nome }
        });
    }

    chiusuraPratica(): boolean {
        if (Number(this.offerta.lat_chiusura_pratica) === Number(this.offerta.step_numero)) {
            return false
        } else {
            return true
        }
    }

    delStepAdmin(id: string): void {
        this.stepDocService.delStepAdmin(id).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: "Step Eliminato Correttamente",
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');
        });
    }

    invioEmailStandard(): void {
        this.stepDocService.InvioEmailStandard(Number(this.route.snapshot.paramMap.get('id')), this.offerta.cl_serramentista).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: res.message,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChat(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChatAdmin(this.route.snapshot.paramMap.get('id') ?? '0');
        });
    }

    invioEmailCriticita(): void {
        this.stepDocService.InvioEmailCriticità(Number(this.route.snapshot.paramMap.get('id'))).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: res.message,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChat(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChatAdmin(this.route.snapshot.paramMap.get('id') ?? '0');
        });
    }



    invioEmailCaricamenti(stepId: number): void {
        this.stepDocService.InvioEmailCaricamenti(Number(this.route.snapshot.paramMap.get('id')), stepId).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: res.message,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChat(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChatAdmin(this.route.snapshot.paramMap.get('id') ?? '0');
        });
    }

    invioEmailDOP(Id: number): void {
        this.stepDocService.InvioEmailDOP(Id).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: res.message,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChat(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChatAdmin(this.route.snapshot.paramMap.get('id') ?? '0');
        });
    }

    invioEmailPRED(Id: number): void {
        this.stepDocService.InvioEmailPRED(Id).subscribe((res) => {
            this.messageSrv.add({
                key: "tst",
                severity: res.res === "ok" ? "success" : "danger",
                summary: `Messaggio`,
                detail: res.message,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChat(this.route.snapshot.paramMap.get('id') ?? '0');
            this.chatLavoriComponent.refreshChatAdmin(this.route.snapshot.paramMap.get('id') ?? '0');
        });
    }

    installatore(numero: number) {
        return (numero > 2 && numero < 6) ? ' - (Responsabile caricamento: ORC)' : ' - (Responsabile caricamento: Installatore)'
    }

    totParsed(): void {
        try {
            const prevPreventivoParsed = JSON.parse(this.offerta?.lav_preventivo.toString());
            return prevPreventivoParsed?.fattura?.totale?.tot;
        } catch (error) {
            return
        }
    }

    Titolarita(): string {
        if (this.offerta.ab_titolarita == 1 || this.offerta.ab_titolarita == 4 || this.offerta.ab_titolarita == 7 || this.offerta.ab_titolarita == 8 ||
            this.offerta.ab_titolarita == 9 || this.offerta.ab_titolarita == 10 || this.offerta.ab_titolarita == 14
        ) { return "NO" }
        return "SI"

    }
}
