import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import {
    Catastale,
    CessionarioFiscale,
    InterventoStato,
    ProdottiInstallati,
    TipoAgevolazione,
    TipoIntervento,
    TitolaritaImmobile,
    ZonaClimatica,
} from "src/app/_interfaces/list-type";
import { ListTypeService } from "src/app/_services/list-type.service";
import { SerramentistaService } from "src/app/_services/serramentista.service";
import { UtentiAttrsService } from "src/app/_services/utenti-attrs.service";
import { CalcoloPreventivoDD } from "./preventivo-form.calcolo-dd";
import { CalcoloPreventivoBCAI } from "./preventivo-form.calcolo-bcai";
import { CalcoloPreventivoBAAI } from "./preventivo-form.calcolo-baai";
import { ChiavaValore } from "src/app/_interfaces/DataChiavaValore";
import { ChiavevaloreService } from "src/app/_services/chiavevalore.service";
import { environment } from 'src/environments/environment';


@Component({
    selector: "app-preventivo-form",
    templateUrl: "./preventivo-form.component.html",
})
export class PreventivoFormComponent implements OnInit {
    constructor(
        private rootFormGroup: FormGroupDirective,
        private route: ActivatedRoute,
        private listTypeSrv: ListTypeService,
        private SerramentistaSrv: SerramentistaService,
        private utenteAttrsSrv: UtentiAttrsService,
        private chiaveValoreSrv: ChiavevaloreService,
        private msgService: MessageService
    ) { }

    @Input() preventivoCalcolato: boolean;
    @Input() dichiarazioneResp: boolean;
    @Output() dichiarazioneRespChange = new EventEmitter<boolean>();
    @Output() preventivoCalcolatoChange = new EventEmitter<boolean>();
    @Input() tipologia: TipoIntervento;
    @Input() disabled: boolean;
    @Input() tipoCliente: string;

    environment = environment

    form!: FormGroup;
    tipAgevolazioni: TipoAgevolazione[];


    chiusuraOscurante: { label: string; value: any }[] = [
        { label: "SI", value: 1 },
        { label: "NO", value: 0 },
        { label: "<=0.35", value: 2 },
    ];
    fisica_giuridica: { label: string; value: any }[] = [
        { label: "NON AGEVOLABILE", value: "giuridica" },
        { label: "AGEVOLABILE", value: "fisica" },
    ];

    AliquotaIvaOfferta: { label: string; value: any }[] = [
        { label: "0%", value: "0" },
        { label: "10%", value: "10" },
        { label: "10% / 22%", value: "1022" },
        { label: "22%", value: "22" },
    ];

    AliquotaIvaOffertaDD: { label: string; value: any }[] = [
        { label: "0%", value: "0" },
        { label: "10%", value: "10" },
        { label: "10% / 22%", value: "1022" },
        { label: "22%", value: "22" },
    ];


    AliquotaIvaOffertaBA: { label: string; value: any }[] = [
        { label: "0%", value: "0" },
        { label: "4%", value: "4" },
        { label: "10%", value: "10" },
        { label: "10% / 22%", value: "1022" },
        { label: "22%", value: "22" },
    ];

    AliquotaIvaAttuazione: { label: string; value: any }[] = [
        { label: "0%", value: "0" },
        { label: "10%", value: "10" },
        { label: "10% / 22%", value: "1022" },
        { label: "22%", value: "22" },
    ];

    tipoVisto: { label: string; value: any }[] = [
        { label: "SI", value: "SI" },
        { label: "NO", value: "NO" },
    ];
    tipoAsseverazione: { label: string; value: any }[] = [
        { label: "NO", value: "NO" },
        { label: "SI", value: "SI" },
    ];

    abPrincipale: { label: string; value: any }[] = [];

    abPrincipaleFiltro: { label: string; value: any }[] = [
        { label: "N/A", value: "N/A" },
        { label: "ABITAZIONE PRINCIPALE", value: "ABITAZIONE PRINCIPALE" },
        { label: "ALTRO IMMOBILE", value: "ALTRO IMMOBILE" },
    ];

    tipoEnea: { label: string; value: any }[] = [
        { label: "NO", value: 0 },
        { label: "SI", value: 1 },
    ];

    tipoProfilo: { label: string; value: any }[] = [
        { label: "RETE", value: "RETE" },
        { label: "EXTRA", value: "EXTRA" },
    ];

    tipoFinanziamento: { label: string; value: any }[] = [
        { label: "NO", value: "NO" },
        { label: "Deutsche Bank", value: "Deutsche Bank" },
        { label: "Altri istituti", value: "Altri istituti" }
    ];

    tipoRisparmioEnergetico: { label: string; value: any }[] = [
        { label: "Energia elettrica", value: "Energia elettrica" },
        { label: "Gas", value: "Gas" }
    ];

    opere: number = 0;
    altre: number = 0;
    prestazioni: number = 0;

    abilitaOneri: number = 0;
    categorieCatastali: Catastale[];

    prevRE: ChiavaValore[];

    ruoloUtente: string = "SE";
    interventoStato: InterventoStato[];
    cessionariFiscali: CessionarioFiscale[];

    mostra: boolean = false;

    filtroChiusuraOscurante: { label: string; value: any }[] =
        this.chiusuraOscurante;
    filtroAliquotaIvaOfferta: { label: string; value: any }[] =
        this.AliquotaIvaOfferta;

    cessionarioFinale: { label: string; value: any }[] = [];
    serramentoTipoProdotto: ProdottiInstallati[] = [];
    filtroTipoProdotto: ProdottiInstallati[];
    tipo: string;
    tipoIntervento: number;

    labelPosainOpera: string;
    labelValoreVendita: string;
    labelBeniSignificativi: string;
    labelAltriBeni: string;
    labelOpereCompl: string;
    labelPrestazioniProf: string;

    zonelimatiche: ZonaClimatica[] = [];
    filtroZonelimatiche: ZonaClimatica[];

    isAdmin: Boolean;

    AliquotaIvaPrestazioneOfferta: { label: string; value: any }[] = [
        { label: "10%", value: "10" },
        { label: "22%", value: "22" },
    ];

    titImmobile: TitolaritaImmobile[];
    eccedenteContributoSpettante: number = 0;

    ngOnInit(): void {


        if (this.rootFormGroup.control.get('prev_id').value == 0) {
            if (this.tipoCliente == 'fisica') {
                this.abPrincipale = this.abPrincipaleFiltro.filter((t) => t.value != "N/A")
            } else {
                this.abPrincipale = this.abPrincipaleFiltro.filter((t) => t.value == "ALTRO IMMOBILE");
            }
        } else {
            if (this.tipoCliente == 'fisica') {
                this.abPrincipale = this.abPrincipaleFiltro
            } else {
                this.abPrincipale = this.abPrincipaleFiltro.filter((t) => t.value == "ALTRO IMMOBILE" && t.value == "N/A");
            }
        }

        this.eccedenteContributoSpettante = 0;
        this.ruoloUtente = sessionStorage.getItem("ruolo");
        this.abilitaOneri = Number(sessionStorage.getItem("abilita_oneri_attuazione"));
        this.isAdmin = sessionStorage.getItem("ruolo") === "AD" ? true : false;
        this.refreshCessionariFinali();
        this.refreshTitolariImmobili();
        this.refreshTipologieAgevolazioni();
        this.refreshCategorieCatastali();
        this.refreshZoneClimatiche();
        this.refreshProdottiInstallati();
        this.refreshCessionarioFinale();
        this.refreshInterventoStati();
        const isEdit = this.route.snapshot.paramMap.get("id") ? true : false;

        this.form = this.rootFormGroup.control.get("preventivoForm") as FormGroup;

        this.tipo = this.rootFormGroup.control.controls["tipologiaSelectForm"].get("prev_tipologia").value;
        this.form.controls.prev_tipologia.setValue(this.tipo);
        this.tipoIntervento = this.rootFormGroup.control.controls["tipologiaSelectForm"].get("prev_tipologia_intervento").value;



        this.form.controls.prev_tipologia_intervento.setValue(this.tipoIntervento);
        this.abilitaOneri = Number(sessionStorage.getItem("abilita_oneri_attuazione"));
        this.ruoloUtente = sessionStorage.getItem("ruolo");
        this.refreshChiaveValore(isEdit)
        if (isEdit) {

            if (this.tipo === 'BA' || this.tipo === 'BA_AI') {
                this.filtroAliquotaIvaOfferta = this.AliquotaIvaOffertaBA
            } else {
                this.filtroAliquotaIvaOfferta = this.AliquotaIvaOffertaDD
            }
            if (this.tipoIntervento !== 1) {
                if (this.tipo === 'BC_AI') {
                    this.AliquotaIvaAttuazione = this.AliquotaIvaAttuazione
                } else {
                    this.AliquotaIvaAttuazione = this.AliquotaIvaOfferta.filter((t) => t.value != "1022");
                }
            }

            this.form.controls.lat_cessionario.setValue(this.form.controls.lat_cessionario.value);
        } else {
            if (this.tipoIntervento == 1) {
                this.form.controls.prev_finanz.setValue(1);
            }
            if (this.tipo === "EB" || this.tipo === "BA" || this.tipo === "BA_AI" || this.tipo === "BC_SI" || this.tipo === "BC_AI") {
                if (this.tipo === 'BA' || this.tipo === 'BA_AI') {
                    this.filtroAliquotaIvaOfferta = this.AliquotaIvaOffertaBA
                } else {
                    this.filtroAliquotaIvaOfferta = this.AliquotaIvaOffertaDD
                }
                if (this.tipoIntervento !== 1 && this.tipo != 'BA_AI') {
                    if (this.tipo === 'BC_AI') {
                        this.AliquotaIvaAttuazione = this.AliquotaIvaAttuazione
                    } else {
                        this.AliquotaIvaAttuazione = this.AliquotaIvaOfferta.filter((t) => t.value != "1022");
                    }
                }


                this.form.controls.prev_iva_prestazioni.setValue("22");
                this.form.controls.prev_pag_desc_1.setValue("");
                this.form.controls.prev_pag_perc_1.setValue("");
                this.form.controls.prev_consegna.setValue("");
            } else {
                this.form.controls.prev_iva_offerta.setValue(null);
                this.form.controls.prev_pag_desc_1.setValue("");
                this.form.controls.prev_pag_perc_1.setValue("");
                this.form.controls.prev_consegna.setValue("");
            }
        }
        this.refreshAttributi();
        // disabilita form se status > 1
        if (this.disabled) {
            this.form.disable();
        }



    }


    refreshChiaveValore(isedit: boolean): void {
        this.chiaveValoreSrv.getAll().subscribe((res) => {
            this.prevRE = [...res.data]
            if (isedit == false) {
                this.form.controls.prev_costoenea.setValue([...res.data].find((x) => x.pre_chiave == "CE_COSTO_ENEA").pre_valore)
            }
        })
    }

    refreshAttributi(): void {
        const tipo =
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get("prev_tipologia").value;
        let attrmin = tipo === "EB" ? "si_dd" : tipo === "BA" ? "ba_dd_si" : tipo === "BA_AI" ? "ba_dd_ai" : tipo === "BC_AI" ? "bc_dd_ai" : "bc_dd"
        this.utenteAttrsSrv
            .getUserAttrs(
                this.rootFormGroup.control.controls["prev_user"].value,
                attrmin
            ).subscribe((res) => {
                this.labelPosainOpera = res.data.find((d) => d.ua_chiave === `${attrmin}_PosaOpera`).ua_valore;
                this.labelValoreVendita = res.data.find((d) => d.ua_chiave === `${attrmin}_VenditaBeni`).ua_valore;
                this.labelBeniSignificativi = res.data.find((d) => d.ua_chiave === `${attrmin}_BeniSignificativi`).ua_valore;
                this.labelAltriBeni = res.data.find((d) => d.ua_chiave === `${attrmin}_VenditaMateriali`).ua_valore;
                this.labelOpereCompl = res.data.find((d) => d.ua_chiave === `${attrmin}_AltreOpere`).ua_valore;
                this.labelPrestazioniProf = res.data.find((d) => d.ua_chiave === `${attrmin}_Prestazioni`).ua_valore;
            })
    }

    refreshInterventoStati(): void {
        this.listTypeSrv.getInterventoStati().subscribe((res) => {
            this.interventoStato = [...res.data];
        });
    }
    refreshCessionariFinali(): void {
        this.SerramentistaSrv.getCessionarifinali().subscribe((res) => {
            this.cessionarioFinale = [];
            [...res.data].forEach((item) => {
                this.cessionarioFinale.push({
                    label: item.serr_ragsoc,
                    value: item.serr_id,
                });
            });
        });
    }
    refreshCessionarioFinale(): void {
        this.listTypeSrv.getCessionariFiscali().subscribe((res) => {
            this.cessionariFiscali = [...res.data];
        });
    }

    refreshProdottiInstallati(): void {
        this.listTypeSrv.getProdottiInstallati().subscribe((res) => {
            this.serramentoTipoProdotto = [...res.data];
            this.filtroTipoProdotto = [...res.data];
        });
    }

    refreshZoneClimatiche(): void {
        this.listTypeSrv.getZonaClimatica().subscribe((res) => {
            this.zonelimatiche = [...res.data];
            this.filtroZonelimatiche = [...res.data];
        });
    }

    refreshCategorieCatastali(): void {
        this.listTypeSrv.getCatastale().subscribe((res) => {
            this.categorieCatastali = [...res.data];
        });
    }

    refreshTitolariImmobili(): void {
        this.listTypeSrv.getTitolaritaImmobile().subscribe((res) => {
            this.titImmobile = [...res.data].filter((item) => item.ti_id != 6 && item.ti_id != 18);
        });
    }

    refreshTipologieAgevolazioni(): void {
        this.listTypeSrv.getTipoAgevolazione().subscribe((res) => {
            this.tipAgevolazioni = [...res.data];
        });
    }

    BloccaIva(ev): void {
        if (ev.value === "SI") {
            this.form.controls.prev_iva_prestazioni.setValue("22");
        }
        if (this.form.controls.prev_iva_offerta.value == "0") {
            this.form.controls.prev_iva_prestazioni.setValue("0");
        }
    }

    modificaIvaPrestazioni = (ev): void => {
        if (ev.value === 0) {
            this.form.controls.prev_iva_prestazioni.setValue("0");
        } else if (ev.value === 10) {
            this.form.controls.prev_iva_prestazioni.setValue("10");
        } else if (ev.value === 1022) {
            this.form.controls.prev_iva_prestazioni.setValue("1022");
        } else {
            this.form.controls.prev_iva_prestazioni.setValue("22");
        }
    };

    azzeraFinaziamento(ev): void {
        if (ev.value == "NO") {
            this.form.get("prev_preventiviAttr.paf_fin_numero_rate").setValue("0")
            this.form.get("prev_preventiviAttr.paf_fin_importo_rate").setValue("0")
            this.form.get("prev_preventiviAttr.paf_fin_interessi").setValue("0")
            this.form.get("prev_preventiviAttr.paf_fin_totale").setValue("0")
            this.form.get("prev_preventiviAttr.paf_fin_saldo").setValue("0")
        }

    }

    valore10Anni(): boolean {
        return Number(this.form.get("prev_preventiviAttr.paf_tot_costo_10anni").value) < 0
    }

    valore30Anni(): boolean {
        return Number(this.form.get("prev_preventiviAttr.paf_tot_costo_30anni").value) < 0
    }

    async CalcolaPreventivo() {

        // if (this.form.get("prev_posa_opera")?.value && Number(this.form.get("prev_posa_opera").value) > 12000) {
        //     this.msgService.add({
        //         key: "tst",
        //         severity: "error",
        //         summary: "Errore",
        //         detail: "Posa in opera non puo' superare Euro 12.000",
        //         life: 8000 // Tempo in millisecondi (10 secondi)
        //     });
        //     return
        // }

        // if (this.form.get("prev_altre_opere")?.value && Number(this.form.get("prev_altre_opere").value) > 2200) {
        //     this.msgService.add({
        //         key: "tst",
        //         severity: "error",
        //         summary: "Errore",
        //         detail: "Altre opere non puo' superare Euro 12.000",
        //         life: 8000 // Tempo in millisecondi (10 secondi)
        //     });
        //     return
        // }

        // if (this.form.get("prev_prestazioni_prof")?.value && Number(this.form.get("prev_prestazioni_prof").value) > 970) {
        //     this.msgService.add({
        //         key: "tst",
        //         severity: "error",
        //         summary: "Errore",
        //         detail: "Prestazioni professionali non puo' superare Euro 970",
        //         life: 8000 // Tempo in millisecondi (10 secondi)
        //     });
        //     return
        // }




        if (this.form.get("prev_abitazione_principale").value == '' || this.form.get("prev_abitazione_principale").value == undefined || this.form.get("prev_abitazione_principale").value == null) {
            this.form.controls.prev_abitazione_principale.setValue("");

            this.form.get('prev_abitazione_principale')?.markAsTouched();
            this.form.get('prev_abitazione_principale')?.markAsDirty();

            this.msgService.add({
                key: "tst",
                severity: "error",
                summary: "Errore",
                detail: "Tipologia Immobile non selezionata",
                life: 8000 // Tempo in millisecondi (10 secondi)
            });


            return;
        }
        if (this.form.get("prev_abitazione_principale").value == "N/A") {
            this.msgService.add({
                key: "tst",
                severity: "info",
                summary: "Attenzione",
                detail: "Tipologia Immobile impostata su N/A, il calcolo sara' comunque effettuato",
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
        }






        if (!this.form.valid) {
            return;
        }
        this.preventivoCalcolatoChange.emit(false);

        const tipo = this.rootFormGroup.control.controls["tipologiaSelectForm"].get("prev_tipologia").value;
        const tipologiaIntervento = this.rootFormGroup.control.controls["tipologiaSelectForm"].get("prev_tipologia_intervento").value;
        if (tipo === "BC_AI") {
            let attr = "BC_DD_AI";
            let attrmin = "bc_dd_ai";
            const { data } = await this.utenteAttrsSrv
                .getUserAttrs(
                    this.rootFormGroup.control.controls["prev_user"].value,
                    attr
                )
                .toPromise();

            if (!data || !data.length) {
                this.msgService.add({
                    key: "tst",
                    severity: "danger",
                    summary: "Errore",
                    detail: "Il fornitore non risulta abilitato, contattare ORC",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }
            const abilitato = data.find(
                (d) => d.ua_chiave === `${attrmin}_check` //"si_dd_check"
            );
            if (!abilitato || abilitato.ua_valore === "false") {
                this.msgService.add({
                    key: "tst",
                    severity: "danger",
                    summary: "Errore",
                    detail: "Per abilitare l'utenza Contattare Officine Rinnovabili",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }

            const calc = new CalcoloPreventivoBCAI(
                this.form,
                this.rootFormGroup.control.controls["tipologiaSelectForm"] as FormGroup,
                this.msgService,
                tipo === "EB" ? "si_dd" : tipo === "BA" ? "ba_dd_si" : tipo === "BC_AI" ? "bc_dd_ai" : "bc_dd",
                this.ruoloUtente,
                data,
                this.prevRE
            );
            try {
                const ok = calc.calcola();
                if (ok) this.preventivoCalcolatoChange.emit(true);
            } catch (e) {
                this.msgService.add({
                    key: "tst",
                    severity: "danger",
                    summary: "Errore",
                    detail: e.message,
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }

        }
        if (tipo === "BA_AI") {
            let attr = "BA_DD_AI";
            let attrmin = "ba_dd_ai";
            const { data } = await this.utenteAttrsSrv
                .getUserAttrs(
                    this.rootFormGroup.control.controls["prev_user"].value,
                    attr
                )
                .toPromise();

            if (!data || !data.length) {
                this.msgService.add({
                    key: "tst",
                    severity: "danger",
                    summary: "Errore",
                    detail: "Il fornitore non risulta abilitato, contattare ORC",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }
            const abilitato = data.find(
                (d) => d.ua_chiave === `${attrmin}_check` //"si_dd_check"
            );
            if (!abilitato || abilitato.ua_valore === "false") {
                this.msgService.add({
                    key: "tst",
                    severity: "danger",
                    summary: "Errore",
                    detail: "Per abilitare l'utenza Contattare Officine Rinnovabili",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }

            const calc = new CalcoloPreventivoBAAI(
                this.form,
                this.rootFormGroup.control.controls["tipologiaSelectForm"] as FormGroup,
                this.msgService,
                tipo === "EB" ? "si_dd" : tipo === "BA" ? "ba_dd_si" : tipo === "BA_AI" ? "ba_dd_ai" : "bc_dd",
                this.ruoloUtente,
                data,
                this.prevRE
            );
            try {
                const ok = calc.calcola();
                if (ok) this.preventivoCalcolatoChange.emit(true);
            } catch (e) {
                this.msgService.add({
                    key: "tst",
                    severity: "danger",
                    summary: "Errore",
                    detail: e.message,
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }

        }
        if (tipo === "EB" || tipo === "BA" || tipo === "BC_SI") {
            if (tipologiaIntervento === 1) {
                // calcolo preventivo eco bonus si
                let attr = tipo === "EB" ? "SI_DD" : tipo === "BA" ? "BA_DD_SI" : "BC_DD";
                let attrmin = tipo === "EB" ? "si_dd" : tipo === "BA" ? "ba_dd_si" : "bc_dd";
                const { data } =
                    await this.utenteAttrsSrv.getUserAttrs(this.rootFormGroup.control.controls["prev_user"].value, attr).toPromise();

                if (!data || !data.length) {
                    this.msgService.add({
                        key: "tst",
                        severity: "danger",
                        summary: "Errore",
                        detail: "Il fornitore non risulta abilitato, contattare ORC",
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    return;
                }
                const abilitato = data.find(
                    (d) => d.ua_chiave === `${attrmin}_check` //"si_dd_check"
                );
                if (!abilitato || abilitato.ua_valore === "false") {
                    this.msgService.add({
                        key: "tst",
                        severity: "danger",
                        summary: "Errore",
                        detail: "Per abilitare l'utenza Contattare Officine Rinnovabili",
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    return;
                }

                const calc = new CalcoloPreventivoDD(
                    this.form,
                    this.rootFormGroup.control.controls[
                    "tipologiaSelectForm"
                    ] as FormGroup,
                    this.msgService,
                    tipo === "EB" ? "si_dd" : tipo === "BA" ? "ba_dd_si" : "bc_dd",
                    this.ruoloUtente,
                    data,
                    this.prevRE
                );
                try {
                    const ok = calc.calcola();

                    const risultati = calc.getRisultati();
                    this.opere = risultati.AA;
                    this.altre = risultati.BB;
                    this.prestazioni = risultati.CC;
                    if (this.opere != 0 || this.altre != 0 || this.prestazioni != 0) {
                        this.dichiarazioneRespChange.emit(true);
                    }else {
                        this.dichiarazioneRespChange.emit(false);
                    }

                    if (ok) this.preventivoCalcolatoChange.emit(true);
                } catch (e) {
                    this.msgService.add({
                        key: "tst",
                        severity: "danger",
                        summary: "Errore",
                        detail: e.message,
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    return;
                }
            } else {
                // calcolo preventivo eco bonus ssco
                const { data } = await this.utenteAttrsSrv
                    .getUserAttrs(
                        this.rootFormGroup.control.controls["prev_user"].value,
                        "SSCO_DD"
                    )
                    .toPromise();
                if (!data || !data.length) {
                    this.msgService.add({
                        key: "tst",
                        severity: "danger",
                        summary: "Errore",
                        detail: "Il fornitore non risulta censito",
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    return;
                }
                const abilitato = data.find(
                    (d) => d.ua_chiave === "ssco_dd_check"
                );
                if (!abilitato || abilitato.ua_valore === "false") {
                    this.msgService.add({
                        key: "tst",
                        severity: "danger",
                        summary: "Errore",
                        detail: "Per abilitare l'utenza Contattare Officine Rinnovabili",
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    return;
                }
                const calc = new CalcoloPreventivoDD(
                    this.form,
                    this.rootFormGroup.control.controls[
                    "tipologiaSelectForm"
                    ] as FormGroup,
                    this.msgService,
                    "ssco_dd",
                    this.ruoloUtente,
                    data,
                    this.prevRE
                );
                try {
                    const ok = calc.calcola();
                    const risultati = calc.getRisultati();
                    this.opere = risultati.AA;
                    this.altre = risultati.BB;
                    this.prestazioni = risultati.CC;

                    if (this.opere != 0 || this.altre != 0 || this.prestazioni != 0) {
                        this.dichiarazioneRespChange.emit(true);
                    }else {
                        this.dichiarazioneRespChange.emit(false);
                    }

                    if (ok) this.preventivoCalcolatoChange.emit(true);
                } catch (e) {
                    this.msgService.add({
                        key: "tst",
                        severity: "danger",
                        summary: "Errore",
                        detail: e.message,
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    return;
                }
            }
        }
    }

    async CalcolaSerr_Costi() {
        if (!this.form.valid) {
            return;
        }
        this.preventivoCalcolatoChange.emit(false);

        const tipo =
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_tipologia"
            ).value;
        const tipologiaIntervento = this.rootFormGroup.control.controls[
            "tipologiaSelectForm"
        ].get("prev_tipologia_intervento").value;
        if (tipo === "BC_AI") {
            let attr = "BC_DD_AI";
            let attrmin = "bc_dd_ai";
            const { data } = await this.utenteAttrsSrv.getUserAttrs(this.rootFormGroup.control.controls["prev_user"].value, attr)
                .toPromise();

            if (!data || !data.length) {
                this.msgService.add({
                    key: "tst",
                    severity: "danger",
                    summary: "Errore",
                    detail: "Il fornitore non risulta abilitato, contattare ORC",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }
            const abilitato = data.find(
                (d) => d.ua_chiave === `${attrmin}_check` //"si_dd_check"
            );
            if (!abilitato || abilitato.ua_valore === "false") {
                this.msgService.add({
                    key: "tst",
                    severity: "danger",
                    summary: "Errore",
                    detail: "Per abilitare l'utenza Contattare Officine Rinnovabili",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }

            const calc = new CalcoloPreventivoBCAI(
                this.form,
                this.rootFormGroup.control.controls[
                "tipologiaSelectForm"
                ] as FormGroup,
                this.msgService,
                tipo === "EB" ? "si_dd" : tipo === "BA" ? "ba_dd_si" : tipo === "BC_AI" ? "bc_dd_ai" : "bc_dd",
                this.ruoloUtente,
                data,
                this.prevRE
            );
            const cser: any = calc.calcolaserr_costi()
            this.msgService.add({
                key: "serr",
                severity: "info",
                summary: "IMPORTI",
                detail: `IMPONIBILE SERR.: ${cser.serr} \n COSTO PRATICA: ${cser.cost}`,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
        }
        if (tipo === "BA_AI") {
            let attr = "BA_DD_AI";
            let attrmin = "ba_dd_ai";
            const { data } = await this.utenteAttrsSrv
                .getUserAttrs(
                    this.rootFormGroup.control.controls["prev_user"].value,
                    attr
                )
                .toPromise();

            if (!data || !data.length) {
                this.msgService.add({
                    key: "tst",
                    severity: "danger",
                    summary: "Errore",
                    detail: "Il fornitore non risulta abilitato, contattare ORC",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }
            const abilitato = data.find(
                (d) => d.ua_chiave === `${attrmin}_check` //"si_dd_check"
            );
            if (!abilitato || abilitato.ua_valore === "false") {
                this.msgService.add({
                    key: "tst",
                    severity: "danger",
                    summary: "Errore",
                    detail: "Per abilitare l'utenza Contattare Officine Rinnovabili",
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
                return;
            }

            const calc = new CalcoloPreventivoBAAI(
                this.form,
                this.rootFormGroup.control.controls[
                "tipologiaSelectForm"
                ] as FormGroup,
                this.msgService,
                tipo === "EB" ? "si_dd" : tipo === "BA" ? "ba_dd_si" : tipo === "BA_AI" ? "ba_dd_ai" : "bc_dd",
                this.ruoloUtente,
                data,
                this.prevRE
            );
            const cser: any = calc.calcolaserr_costi()
            this.msgService.add({
                key: "serr",
                severity: "info",
                summary: "IMPORTI",
                detail: `IMPONIBILE SERR.: ${cser.serr} \n COSTO PRATICA: ${cser.cost}`,
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
        }
        if (tipo === "EB" || tipo === "BA" || tipo === "BC_SI") {
            if (tipologiaIntervento === 1) {
                // calcolo preventivo eco bonus si
                let attr = tipo === "EB" ? "SI_DD" : tipo === "BA" ? "BA_DD_SI" : "BC_DD";
                let attrmin = tipo === "EB" ? "si_dd" : tipo === "BA" ? "ba_dd_si" : "bc_dd";
                const { data } = await this.utenteAttrsSrv
                    .getUserAttrs(
                        this.rootFormGroup.control.controls["prev_user"].value,
                        attr
                    )
                    .toPromise();

                if (!data || !data.length) {
                    this.msgService.add({
                        key: "tst",
                        severity: "danger",
                        summary: "Errore",
                        detail: "Il fornitore non risulta abilitato, contattare ORC",
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    return;
                }
                const abilitato = data.find(
                    (d) => d.ua_chiave === `${attrmin}_check` //"si_dd_check"
                );
                if (!abilitato || abilitato.ua_valore === "false") {
                    this.msgService.add({
                        key: "tst",
                        severity: "danger",
                        summary: "Errore",
                        detail: "Per abilitare l'utenza Contattare Officine Rinnovabili",
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    return;
                }

                const calc = new CalcoloPreventivoDD(
                    this.form,
                    this.rootFormGroup.control.controls[
                    "tipologiaSelectForm"
                    ] as FormGroup,
                    this.msgService,
                    tipo === "EB" ? "si_dd" : tipo === "BA" ? "ba_dd_si" : "bc_dd",
                    this.ruoloUtente,
                    data,
                    this.prevRE
                );
                const cser: any = calc.calcolaserr_costi()
                const risultati = calc.getRisultati();
                this.opere = risultati.AA;
                this.altre = risultati.BB;
                this.prestazioni = risultati.CC;

                if (this.opere != 0 || this.altre != 0 || this.prestazioni != 0) {
                    this.dichiarazioneRespChange.emit(true);
                }else {
                    this.dichiarazioneRespChange.emit(false);
                }

                this.msgService.add({
                    key: "serr",
                    severity: "info",
                    summary: "IMPORTI",
                    detail: `IMPONIBILE SERR.: ${cser.serr} \n COSTO PRATICA: ${cser.cost}`,
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });
            } else {
                // calcolo preventivo eco bonus ssco
                const { data } = await this.utenteAttrsSrv
                    .getUserAttrs(
                        this.rootFormGroup.control.controls["prev_user"].value,
                        "SSCO_DD"
                    )
                    .toPromise();
                if (!data || !data.length) {
                    this.msgService.add({
                        key: "tst",
                        severity: "danger",
                        summary: "Errore",
                        detail: "Il fornitore non risulta censito",
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    return;
                }
                const abilitato = data.find(
                    (d) => d.ua_chiave === "ssco_dd_check"
                );
                if (!abilitato || abilitato.ua_valore === "false") {
                    this.msgService.add({
                        key: "tst",
                        severity: "danger",
                        summary: "Errore",
                        detail: "Per abilitare l'utenza Contattare Officine Rinnovabili",
                        life: 8000 // Tempo in millisecondi (10 secondi)
                    });
                    return;
                }
                const calc = new CalcoloPreventivoDD(
                    this.form,
                    this.rootFormGroup.control.controls[
                    "tipologiaSelectForm"
                    ] as FormGroup,
                    this.msgService,
                    "ssco_dd",
                    this.ruoloUtente,
                    data,
                    this.prevRE
                );
                const cser: any = calc.calcolaserr_costi()
                const risultati = calc.getRisultati();
                this.opere = risultati.AA;
                this.altre = risultati.BB;
                this.prestazioni = risultati.CC;

                if (this.opere != 0 || this.altre != 0 || this.prestazioni != 0) {
                    this.dichiarazioneRespChange.emit(true);
                }else {
                    this.dichiarazioneRespChange.emit(false);
                }

                this.msgService.add({
                    key: "serr",
                    severity: "info",
                    summary: "IMPORTI",
                    detail: `IMPONIBILE SERR.: ${cser.serr} \n COSTO PRATICA: ${cser.cost}`,
                    life: 8000 // Tempo in millisecondi (10 secondi)
                });

            }
        }
    }

}
